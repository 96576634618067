import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Segment, Tab, TabPane } from "semantic-ui-react";

import { useShouldShowFacebookPage } from "Common/utils/featureFlags";

import { NavLink } from "react-router-dom";
import styled from "styled-components";

const StyledConfigureCampaignsTabPane = styled(TabPane)`
  height: 87vh;
  display: flex !important;
  flex-direction: column;
  gap: 1em;
  overflow-y: hidden;
`;

const panes = [
  {
    menuItem: {
      as: NavLink,
      id: "tab1",
      content: "Configure Campaigns",
      to: "./configure",
      exact: true,
      key: "configure"
    },
    render: () => (
      <StyledConfigureCampaignsTabPane>
        <Outlet />
      </StyledConfigureCampaignsTabPane>
    )
  },
  {
    menuItem: {
      as: NavLink,
      id: "tab1",
      content: "Performance",
      to: "./performance",
      exact: true,
      key: "performance"
    },
    render: () => (
      <StyledConfigureCampaignsTabPane>
        <Outlet />
      </StyledConfigureCampaignsTabPane>
    )
  }
];

export function FacebookPage(): JSX.Element {
  const shouldShowFBPage = useShouldShowFacebookPage();

  if (!shouldShowFBPage) {
    return <Navigate to="../dashboard/campaigns" replace={true} />;
  }

  const activeTab = panes.findIndex(pane =>
    window.location.pathname.includes(pane.menuItem.key)
  );
  return (
    <Segment>
      <Tab activeIndex={Math.max(activeTab, 0)} panes={panes} />
    </Segment>
  );
}
