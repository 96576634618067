import React, { useContext } from "react";
import { Checkbox } from "semantic-ui-react";

import { WantsInDevelopmentFeaturesContext } from "ExtensionV2";

// Manages the state of the in development features toggle.
export function InDevelopmentFeaturesToggle(): JSX.Element {
  const [
    wantsInDevelopmentFeatures,
    setWantsInDevelopmentFeatures
  ] = useContext(WantsInDevelopmentFeaturesContext);

  const handleToggleWantAmpdProOperatorFeatures = () => {
    setWantsInDevelopmentFeatures(!wantsInDevelopmentFeatures);
  };

  return (
    <Checkbox
      label="Enable"
      onClick={handleToggleWantAmpdProOperatorFeatures}
      checked={wantsInDevelopmentFeatures}
    />
  );
}
