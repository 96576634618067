export const AMPD_PRO_OP_FEATURES_LOCAL_STORAGE_KEY = "ampdProOpFeatures";
export const DEV_FEATURES_LOCAL_STORAGE_KEY = "devFeatures";

// Returns whether the user is an Ampd Pro Operator, essentially a user who
// can see advanced or beta UI features.  For now, all superusers will
// qualify as operators, but we may want to add other users who have
// certain qualifications.  In either event, it shouldn't be used to reveal
// secure information, and it doesn't replace proper rpc authorization.
export function checkIsAmpdProOperator(user) {
  return !!user?.isAmpdOperator;
}

// Returns whether the current user want to see advanced and/or beta features
// in the UI, if they happen to have operator privileges.
export function getWantAmpdProOperatorFeatures() {
  return (
    localStorage.getItem(AMPD_PRO_OP_FEATURES_LOCAL_STORAGE_KEY) === "true"
  );
}

// Sets whether the current user want to see advanced and/or beta features
// in the UI, if they happen to have operator privileges.  This can be set
// whether the current user is an operator, but the features themselves
// should verify this using isAmpdProOperator.
export function setWantAmpdProOperatorFeatures(want) {
  if (want) {
    localStorage.setItem(AMPD_PRO_OP_FEATURES_LOCAL_STORAGE_KEY, "true");
  } else {
    localStorage.removeItem(AMPD_PRO_OP_FEATURES_LOCAL_STORAGE_KEY);
  }
}

// In development features are not ready for customers to use, but are
// available for developers to test.  This is separate from operator
// features, which are usually complete but for internal use only.
export function getWantInDevelopmentFeatures() {
  return localStorage.getItem(DEV_FEATURES_LOCAL_STORAGE_KEY) === "true";
}

export function setWantInDevelopmentFeatures(want) {
  if (want) {
    localStorage.setItem(DEV_FEATURES_LOCAL_STORAGE_KEY, "true");
  } else {
    localStorage.removeItem(DEV_FEATURES_LOCAL_STORAGE_KEY);
  }
}
