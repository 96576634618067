import React from "react";
import "url-search-params-polyfill"; /* To standardize the URLSearchParams type. */
import "react-resizable/css/styles.css";
import Common from "./Common";

function Index(props) {
  return <Common {...props} />;
}

export { Index };
