import { Button, Form, Input, Message } from "semantic-ui-react";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import {
  signUpPath,
  signUpPathExistingUserParam,
  signUpPathExtraAdminEmailParam
} from "../ExtensionV2";
import { isValidEmail } from "Common/utils/email";
import { navigateToPage } from "../util";

export function NewAmpdAccountForm(props) {
  const { siteAlias } = props;

  const location = useLocation();

  const [email, setEmail] = useState("");

  const handleEmailChanged = (e, { value }) => {
    setEmail(value);
  };

  const handleNewAccountButtonClicked = () => {
    const params = {
      [signUpPathExistingUserParam]: true
    };

    if (email) {
      params[signUpPathExtraAdminEmailParam] = email;
    }

    navigateToPage({
      location,
      siteAlias,
      toPage: signUpPath,
      params
    });
  };

  if (!siteAlias) {
    return null;
  }

  return (
    <>
      <Form>
        <Form.Field>
          <p>
            <small>
              You will be an Admin user for the new account. If you want an
              additional Admin user, enter their Google email address below.
              They will be able to log into the new account and set up payment.
            </small>
          </p>
          <label htmlFor="email">
            Secondary Admin user email for new account (optional)
          </label>
          <Input
            name="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChanged}
          />
        </Form.Field>
        <Button primary onClick={handleNewAccountButtonClicked}>
          Create New Account
        </Button>
      </Form>
      {email && !isValidEmail(email) && (
        <Message error>Incomplete or invalid email address.</Message>
      )}
    </>
  );
}

export default NewAmpdAccountForm;
