import { useQuery, UseQueryResult } from "@tanstack/react-query";

import {
  GetFacebookMarketingResourcesReply,
  GetFacebookMarketingResourcesRequest
} from "Common/proto/edge/grpcwebPb/grpcweb_Facebook_pb";
import { GRPCWebClient } from "Common/utils/grpc";
import getSiteAliasFromURL from "Common/utils/getSiteAliasFromURL";

export const STORED_FACEBOOK_MARKETING_RESOURCES_KEY =
  "storedFacebookMarketingResources";

export const getFacebookMarketingQueryKey = (
  siteAlias: string,
  fields: string[],
  adAccountId = "",
  campaignIds: string[] = [],
  adSetIds: string[] = [],
  adIds: string[] = []
): Array<string | Array<string>> => [
  STORED_FACEBOOK_MARKETING_RESOURCES_KEY,
  siteAlias,
  fields,
  adAccountId,
  campaignIds,
  adSetIds,
  adIds
];

export const useFacebookMarketingResources = ({
  enabled,
  fields,
  adAccountId = "",
  campaignIds = [],
  adSetIds = [],
  adIds = []
}: {
  enabled: boolean;
  fields: string[];
  adAccountId?: string;
  campaignIds?: string[];
  adSetIds?: string[];
  adIds?: string[];
}): UseQueryResult<GetFacebookMarketingResourcesReply.AsObject, unknown> => {
  const siteAlias = getSiteAliasFromURL("/t");

  return useQuery({
    queryKey: getFacebookMarketingQueryKey(
      siteAlias,
      fields,
      adAccountId,
      campaignIds,
      adSetIds,
      adIds
    ),
    staleTime: 15 * 60 * 1_000, // 15 minutes
    enabled: enabled && !!siteAlias,
    queryFn: async () => {
      const req = new GetFacebookMarketingResourcesRequest();
      req.setSiteAlias(siteAlias);

      if (adAccountId) {
        req.setAdAccountId(adAccountId);
      }

      if (campaignIds.length) {
        req.setCampaignIdsList(campaignIds);
      }

      if (adSetIds.length) {
        req.setAdSetIdsList(adSetIds);
      }

      if (adIds.length) {
        req.setAdIdsList(adIds);
      }

      req.setFieldsList(fields);

      const reply = await GRPCWebClient.getFacebookMarketingResources(req, {});
      return reply.toObject();
    }
  });
};
