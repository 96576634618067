import React, { useEffect, useMemo, useState } from "react";
import { generatePath } from "react-router-dom";

import SimpleTooltip from "ExtensionV2/components/SimpleTooltip";
import { Icon } from "semantic-ui-react";
import {
  NARROW_VIEW,
  NavItemIcon,
  NavItemLabel,
  SidebarNavItem,
  SidebarNavItemTooltip
} from "ExtensionV2/components/layout/AmpdGridItems";

import {
  ACCOUNTS_PATH,
  AMPD_ROOT,
  AUTOMATION_PATH,
  CAMPAIGN_DETAILS_DEFAULT_CHARTS_PATH,
  CAMPAIGNS_PATH,
  EXPORTS_PATH,
  GOOGLE_ADS_HISTORY_PATH,
  LEARN_PATH,
  SETTINGS_PATH,
  PRODUCTS_PATH,
  CREATE_CAMPAIGN_PATH,
  BRAND_OVERVIEW_PATH,
  FACEBOOK_PATH,
  FACEBOOK_PERFORMANCE_TAB_PATH,
  FACEBOOK_PAGE_V2_PATH
} from "ExtensionV2/ExtensionV2";

const AmpdNavBarItem = ({
  name,
  icon,
  tooltipText,
  to,
  end
}: {
  name: string;
  icon: React.ReactNode;
  tooltipText?: string;
  to: string;
  end?: boolean;
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Unfortunately the combination of how Semantic UI implements Popover and our use of Styled Components
  // makes it impossible (?) to target this tooltip with a css media-query. So if we only want to
  // show a tooltip on a narrow screen we need to use JS.
  useEffect(() => {
    const setWindowWidthState = (_ev: UIEvent) => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", setWindowWidthState);
    return () => window.removeEventListener("resize", setWindowWidthState);
  }, []);

  const narrowScreen = useMemo(() => {
    return windowWidth < NARROW_VIEW;
  }, [windowWidth]);

  return (
    <SidebarNavItemTooltip
      tooltip={tooltipText || name}
      position="right center"
      disabled={!narrowScreen}
    >
      <SidebarNavItem to={to} end={end}>
        <NavItemIcon>{icon}</NavItemIcon>
        <NavItemLabel>{name}</NavItemLabel>
      </SidebarNavItem>
    </SidebarNavItemTooltip>
  );
};

export const AccountsIcon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    name="Accounts"
    icon={<Icon name="sitemap" size="large" color="black" />}
    to={generatePath(`${AMPD_ROOT}/${ACCOUNTS_PATH}`, {
      siteAlias
    })}
  />
);

export const BrandOverviewIcon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    name="Overview"
    icon={<Icon name="dashboard" size="large" color="black" />}
    to={generatePath(`${AMPD_ROOT}/${BRAND_OVERVIEW_PATH}`, {
      siteAlias
    })}
  />
);

export const CampaignsIcon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    name="Campaigns"
    icon={<Icon name="home" size="large" color="black" />}
    to={generatePath(`${AMPD_ROOT}/${CAMPAIGNS_PATH}`, {
      siteAlias
    })}
    end
  />
);

export const CreateCampaignsIcon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    name="Create Campaigns"
    icon={<Icon name="plus" size="large" color="black" />}
    to={generatePath(`${AMPD_ROOT}/${CREATE_CAMPAIGN_PATH}`, {
      siteAlias
    })}
  />
);

export const ImpressionsIcon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    name="Impressions"
    icon={<Icon name="area graph" size="large" color="black" />}
    to={generatePath(`${AMPD_ROOT}/${CAMPAIGN_DETAILS_DEFAULT_CHARTS_PATH}`, {
      siteAlias
    })}
  />
);

export const ProductsIcon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    name="Products"
    icon={<Icon name="cart" size="large" color="black" />}
    to={generatePath(`${AMPD_ROOT}/${PRODUCTS_PATH}`, {
      siteAlias
    })}
  />
);

export const AutomationIcon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    name="Automation"
    icon={<Icon name="bolt" size="large" color="black" />}
    to={generatePath(`${AMPD_ROOT}/${AUTOMATION_PATH}`, {
      siteAlias
    })}
  />
);

export const ExportsIcon = ({
  siteAlias,
  isSiteAdmin
}: {
  siteAlias: string;
  isSiteAdmin: boolean;
}): JSX.Element => (
  <SimpleTooltip
    tooltip="Only available for Admin users"
    position="right center"
    disabled={isSiteAdmin}
  >
    {isSiteAdmin ? (
      <AmpdNavBarItem
        name="Exports"
        icon={<Icon name="file excel outline" size="large" color="black" />}
        to={EXPORTS_PATH}
      />
    ) : (
      <SidebarNavItem
        to={generatePath(`${AMPD_ROOT}/${EXPORTS_PATH}`, {
          siteAlias
        })}
      >
        <NavItemIcon>
          <Icon
            disabled={!isSiteAdmin}
            name="file excel outline"
            size="large"
            color="black"
          />
        </NavItemIcon>
        <NavItemLabel disabled={!isSiteAdmin}>Exports</NavItemLabel>
      </SidebarNavItem>
    )}
  </SimpleTooltip>
);

export const HistoryIcon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    name="History"
    icon={<Icon name="history" size="large" color="black" />}
    to={generatePath(`${AMPD_ROOT}/${GOOGLE_ADS_HISTORY_PATH}`, {
      siteAlias
    })}
  />
);

export const LearnIcon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    name="Learn"
    icon={<Icon name="book" size="large" color="black" />}
    to={generatePath(`${AMPD_ROOT}/${LEARN_PATH}`, { siteAlias })}
  />
);

export const SettingsIcon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    name="Settings"
    icon={<Icon name="sun outline" size="large" color="black" />}
    to={generatePath(`${AMPD_ROOT}/${SETTINGS_PATH}`, { siteAlias })}
  />
);

export const FacebookIcon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    name="Meta Ads"
    icon={<Icon name="align center" size="large" color="black" />}
    to={generatePath(
      `${AMPD_ROOT}/${FACEBOOK_PATH}/${FACEBOOK_PERFORMANCE_TAB_PATH}`,
      { siteAlias }
    )}
  />
);

export const FacebookV2Icon = ({
  siteAlias
}: {
  siteAlias: string;
}): JSX.Element => (
  <AmpdNavBarItem
    name="Meta"
    icon={<Icon name="warning" size="large" color="black" />}
    to={generatePath(`${AMPD_ROOT}/${FACEBOOK_PAGE_V2_PATH}`, { siteAlias })}
  />
);
