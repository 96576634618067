import "react-resizable/css/styles.css";

import { Colors, GlobalStyle } from "extensionV2.css.js";
import React, { lazy, useContext, useEffect, useMemo } from "react";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";
import { hotjar } from "react-hotjar";
import Notifications from "react-notify-toast";
import {
  generatePath,
  Navigate,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import { createGlobalStyle, ThemeProvider } from "styled-components/macro";

import { markApplicationUsage } from "Common/utils/usage";
import { sendGTMEvent } from "./components/GA";
import AmpdWrapper from "./components/layout/AmpdWrapper";
import RequireAuth from "./components/RequireAuth";
import RequirePayment from "./components/RequirePayment";
import AmpdPage from "./pages/AmpdPage";
import { Site, User } from "./queries/useSession";

import { WantsAmpdProOperatorFeaturesContext } from "ExtensionV2";
import { useBrandOverviewPageEnabled } from "Common/utils/featureFlags";

import getSiteAliasFromURL from "Common/utils/getSiteAliasFromURL";
import { SiteManagerLinkClient } from "Common/proto/entity/siteManagerLink_pb";
import { isManagerSite } from "Common/utils/site";
import { FacebookPage } from "./pages/FacebookPage/FacebookPage";
import { ConfigureCampaignsTabPane } from "./pages/FacebookPage/ConfigureCampaignsTabPane";
import { AdPerformanceTab } from "./pages/FacebookPage/AdPerformanceTab";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAuditInfoRefreshCount } from "./state/useCampaignObjects";
import { MarketplaceInfo } from "Common/utils/marketplace";
import { None } from "Common/utils/tsUtils";
import { FacebookPageV2 } from "./pages/FacebookPageV2/FacebookPageV2";

export const AUDIT_PAGE_GA_CATEGORY = "Ampd: Assessment Dashboard";
export const CAMPAIGN_DASHBOARD_GA_CATEGORY = "Ampd: Campaign Dashboard";

const tagManagerArgs = {
  gtmId: "GTM-NWDSFBH"
};

TagManager.initialize(tagManagerArgs);

export const AMPD_APP_PATH_SESSION_STORAGE = "ampd_app_path";

export const PageNoScrollGlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const SignUpPage = lazy(() => import("ExtensionV2/pages/SignUpPage"));

const PaymentPage = lazy(() =>
  import("ExtensionV2/pages/PaymentPage/PaymentPage")
);

const SiteOnboardingPage = lazy(() =>
  import("ExtensionV2/pages/SiteOnboarding/SiteOnboardingPage")
);

export const signUpPath = "/t/_/welcome";
export const signUpPathExistingUserParam = "existingUser";
export const signUpPathExtraAdminEmailParam = "extraAdminEmail";

export const siteWideCampaignCreatePath =
  "/t/:siteAlias/site-wide-campaign/create";
export const createGoogleAdsAccountPath =
  "/t/:siteAlias/onboarding/create-google-ads-account";
export const createdGoogleAdsAccountPath =
  "/t/:siteAlias/onboarding/created-google-ads-account";
// TODO: verify and remove in favor of PAYMENT_PATH, involves replacing navigateToPage with
// react router useNavigate in several callsites.
export const paymentPath = "/t/:siteAlias/payment";
export const subscriptionCheckoutPath = "/t/:siteAlias/subscription/checkout";
export const createProductAdsPath = "/t/:siteAlias/product-ads/create";
export const linkToAmazonSellerPath = "/t/:siteAlias/link-to-amazon-seller";
export const siteOnboardingPagePath = "/t/:siteAlias/setup";
export const onboardingSuccessPath = "/t/:siteAlias/onboarding/success";

// Dashboard routes
export const DASHBOARD_PATH = ":siteAlias/dashboard";

export const ACCOUNTS_PATH = "dashboard/accounts";
const ACCOUNTS_PAGE_NAME = "Accounts";

export const BRAND_OVERVIEW_PATH = "dashboard/brandOverview";
const BRAND_OVERVIEW_NAME = "Brand Overview";

export const CAMPAIGNS_PATH = "dashboard/campaigns";
const CAMPAIGNS_PAGE_NAME = "Campaigns";

export const CAMPAIGN_ID_QUERY_PARAM = "campaign";
export const MARKETPLACE_QUERY_PARAM = "marketplace";

export function buildCampaignsPath(
  campaignId: number | string | None,
  marketplaceInfo: MarketplaceInfo | None
): string {
  const searchParams = new URLSearchParams();
  if (campaignId) {
    searchParams.set(CAMPAIGN_ID_QUERY_PARAM, String(campaignId));
  }
  if (marketplaceInfo) {
    searchParams.set(MARKETPLACE_QUERY_PARAM, marketplaceInfo.domain);
  }
  return `${CAMPAIGNS_PATH}?${searchParams.toString()}`;
}

export const COPY_CAMPAIGN_PATH = "dashboard/campaigns/:campaignId/copy";
const COPY_CAMPAIGN_PAGE_NAME = "Copy Campaign";

export const CAMPAIGN_DETAILS_PATH = "dashboard/campaigns/:campaignId";
const CAMPAIGN_DETAILS_PAGE_NAME = "Campaign Details";
export const CAMPAIGN_DETAILS_DEFAULT_CHARTS_PATH =
  "dashboard/campaigns/0/charts";

export const PRODUCTS_PATH = "dashboard/products";
const PRODUCTS_PAGE_NAME = "Amazon Products";

// PERFORMANCE_PATH is deprecated, now points to PRODUCTS_PATH
export const PERFORMANCE_PATH = "dashboard/performance";

export const AUTOMATION_PATH = "dashboard/automation";
const AUTOMATION_PAGE_NAME = "Automation";

export const GOOGLE_ADS_HISTORY_PATH = "dashboard/googleAdsHistory";
const GOOGLE_ADS_HISTORY_PAGE_NAME = "Google Ads History";

export const LEARN_PATH = "dashboard/learn";
const LEARN_PAGE_NAME = "Learning Center";

export const EXPORTS_PATH = "dashboard/exports";
const EXPORTS_PAGE_NAME = "Exports";

export const SETTINGS_PATH = "dashboard/settings";
const SETTINGS_PAGE_NAME = "Settings";

export const PAYMENT_PATH = "payment";
const SETUP_PATH = "setup";
const WELCOME_PATH = "welcome";

export const FACEBOOK_PAGE_V2_PATH = "dashboard/meta";
const FACEBOOK_PAGE_V2_NAME = "Meta";

export const CREATE_CAMPAIGN_PATH = "dashboard/create-campaign";
export const CREATE_CAMPAIGN_NAME = "Create Campaign";

export const AMPD_ROOT = "/t/:siteAlias";

export const FACEBOOK_PATH = "meta-beta";
const FACEBOOK_PAGE_NAME = "Meta Beta";
export const FACEBOOK_CONFIGURE_TAB_PATH = "configure";
export const FACEBOOK_PERFORMANCE_TAB_PATH = "performance";

const AccountsPage = lazy(() => import("./pages/AccountsPage/AccountsPage"));
const CampaignsPage = lazy(() => import("./pages/CampaignsPage/CampaignsPage"));
const CampaignDetailsPage = lazy(() => import("./pages/CampaignDetailsPage"));
const ProductsPage = lazy(() => import("./pages/ProductsPage/ProductsPage"));
const LearningCenterPage = lazy(() => import("./pages/LearningCenterPage"));
const ExportsPage = lazy(() => import("./pages/ExportsPage"));
const AutomationPage = lazy(() => import("./pages/AutomationPage"));
const GoogleAdsHistoryPage = lazy(() => import("./pages/GoogleAdsHistoryPage"));
const SettingsPage = lazy(() => import("./pages/SettingsPage/SettingsPage"));
const CampaignSetupPage = lazy(() =>
  import("./pages/CampaignSetupPage/CampaignSetupPage")
);
const BrandOverviewPage = lazy(() =>
  import("./pages/BrandOverviewPage/BrandOverviewPage")
);

function ExtensionV2({
  isAuthenticated,
  user,
  currentSite,
  clientSitesList
}: {
  isAuthenticated: boolean;
  user: User;
  currentSite: Site;
  clientSitesList: Array<SiteManagerLinkClient.AsObject>;
}): JSX.Element {
  const { siteName, siteAlias, siteFeatures } = currentSite;
  const { isCurrentSiteAdmin, userEmail, isAmpdOperator } = user;

  // Initialize hotjar.
  useEffect(() => {
    if (process.env.REACT_APP_HOTJAR_ID && process.env.REACT_APP_HOTJAR_SV) {
      hotjar.initialize(
        Number(process.env.REACT_APP_HOTJAR_ID),
        Number(process.env.REACT_APP_HOTJAR_SV)
      );
    }
  }, []);

  // Mark the "ampd" application as used and log to GTM
  useEffect(() => {
    markApplicationUsage({
      siteAlias,
      application: "ampd"
    });

    sendGTMEvent({
      event: "init-ampd",
      action: "Init Ampd",
      alias: siteAlias,
      email: userEmail
    });
  }, [siteAlias, userEmail]);

  const [wantAmpdProOperatorFeatures] = useContext(
    WantsAmpdProOperatorFeaturesContext
  );

  const googleAdsHistoryEnabled = useMemo(() => {
    return (
      siteFeatures.googleAdsHistoryEnabled ||
      (isAmpdOperator && wantAmpdProOperatorFeatures)
    );
  }, [isAmpdOperator, siteFeatures, wantAmpdProOperatorFeatures]);

  const automationPageEnabled = useMemo(() => {
    return isAmpdOperator && wantAmpdProOperatorFeatures;
  }, [isAmpdOperator, wantAmpdProOperatorFeatures]);

  // TODO(clint): Remove this feature flag after full release of BrandOverviewPage
  const brandOverviewPageEnabled = useBrandOverviewPageEnabled();

  const defaultRoute = useMemo(() => {
    if (!siteAlias) {
      return signUpPath;
    }

    const isManager = isManagerSite(currentSite, clientSitesList);
    let defaultPath;
    if (isManager) {
      defaultPath = `${AMPD_ROOT}/${ACCOUNTS_PATH}`;
    } else {
      defaultPath = `${AMPD_ROOT}/${CAMPAIGNS_PATH}`;
    }

    return generatePath(defaultPath, {
      siteAlias: siteAlias
    });
  }, [siteAlias, currentSite, clientSitesList]);

  const location = useLocation();
  useEffect(() => {
    const targetSiteAlias = getSiteAliasFromURL("/t");
    if (!!siteAlias && !!targetSiteAlias && targetSiteAlias !== siteAlias) {
      // If the siteAlias specified in the location URL is different from the
      // session's siteAlias, reload the page completely so the session and all
      // other redux state can be built from scratch.
      window.location.reload();
    }
  }, [siteAlias, location]);

  return (
    <ThemeProvider theme={Colors}>
      <main>
        <Helmet
          defaultTitle={siteName ? `${siteName} - Ampd` : "Ampd"}
          titleTemplate={siteName ? `%s - ${siteName} - Ampd` : "%s - Ampd"}
        />
        <Notifications options={{ zIndex: 10000 }} />

        <Routes>
          <Route
            path={":siteAlias"}
            element={
              <RequireAuth
                isAuthenticated={isAuthenticated}
                siteAlias={siteAlias}
                fallbackRoute={defaultRoute}
              >
                {
                  <AmpdWrapper
                    automationPageEnabled={automationPageEnabled}
                    googleAdsHistoryEnabled={googleAdsHistoryEnabled}
                    brandOverviewPageEnabled={brandOverviewPageEnabled}
                    currentSite={currentSite}
                    user={user}
                    clientSitesList={clientSitesList}
                  />
                }
              </RequireAuth>
            }
          >
            <Route
              index
              element={<Navigate to={defaultRoute} replace={true} />}
            />

            <Route
              path={WELCOME_PATH}
              element={<SignUpPage currentSite={currentSite} user={user} />}
            />

            <Route
              path={PAYMENT_PATH}
              element={<PaymentPage currentSite={currentSite} user={user} />}
            />

            <Route
              path={SETUP_PATH}
              element={
                <RequirePayment>
                  <SiteOnboardingPage />
                </RequirePayment>
              }
            />

            <Route
              path={ACCOUNTS_PATH}
              element={
                <RequirePayment>
                  <AmpdPage pageName={ACCOUNTS_PAGE_NAME}>
                    <AccountsPage />
                  </AmpdPage>
                </RequirePayment>
              }
            />

            <Route
              path={BRAND_OVERVIEW_PATH}
              element={
                <RequirePayment>
                  <AmpdPage pageName={BRAND_OVERVIEW_NAME}>
                    {brandOverviewPageEnabled ? (
                      <BrandOverviewPage currentSite={currentSite} />
                    ) : (
                      <Navigate
                        to={generatePath(`${AMPD_ROOT}/${CAMPAIGNS_PATH}`, {
                          siteAlias
                        })}
                        replace={true}
                      />
                    )}
                  </AmpdPage>
                </RequirePayment>
              }
            />

            <Route
              path={CAMPAIGNS_PATH}
              element={
                <RequirePayment>
                  <AmpdPage pageName={CAMPAIGNS_PAGE_NAME}>
                    <CampaignsPage />
                  </AmpdPage>
                </RequirePayment>
              }
            />
            <Route
              path={`${CAMPAIGN_DETAILS_PATH}/*`}
              element={
                <RequirePayment>
                  <AmpdPage pageName={CAMPAIGN_DETAILS_PAGE_NAME}>
                    <CampaignDetailsPage
                      auditInfoRefreshCount={getAuditInfoRefreshCount()}
                    />
                  </AmpdPage>
                </RequirePayment>
              }
            />
            <Route
              path={PERFORMANCE_PATH}
              element={
                // TODO(clint): Temporary for deprecation; remove this route later.
                <Navigate
                  to={generatePath(`${AMPD_ROOT}/${PRODUCTS_PATH}`, {
                    siteAlias
                  })}
                  replace={true}
                />
              }
            />
            <Route
              path={PRODUCTS_PATH}
              element={
                <RequirePayment>
                  <AmpdPage pageName={PRODUCTS_PAGE_NAME}>
                    <ProductsPage currentSite={currentSite} />
                  </AmpdPage>
                </RequirePayment>
              }
            />
            <Route
              path={LEARN_PATH}
              element={
                <RequirePayment>
                  <AmpdPage pageName={LEARN_PAGE_NAME}>
                    <LearningCenterPage />
                  </AmpdPage>
                </RequirePayment>
              }
            />
            {isCurrentSiteAdmin && (
              <Route
                path={EXPORTS_PATH}
                element={
                  <RequirePayment>
                    <AmpdPage pageName={EXPORTS_PAGE_NAME}>
                      <ExportsPage />
                    </AmpdPage>
                  </RequirePayment>
                }
              />
            )}
            {automationPageEnabled && (
              <Route
                path={AUTOMATION_PATH}
                element={
                  <RequirePayment>
                    <AmpdPage pageName={AUTOMATION_PAGE_NAME}>
                      <AutomationPage />
                    </AmpdPage>
                  </RequirePayment>
                }
              />
            )}
            {googleAdsHistoryEnabled && (
              <Route
                path={GOOGLE_ADS_HISTORY_PATH}
                element={
                  <RequirePayment>
                    <AmpdPage pageName={GOOGLE_ADS_HISTORY_PAGE_NAME}>
                      <GoogleAdsHistoryPage />
                    </AmpdPage>
                  </RequirePayment>
                }
              />
            )}
            <Route
              path={SETTINGS_PATH}
              element={
                <RequirePayment>
                  <AmpdPage pageName={SETTINGS_PAGE_NAME}>
                    <SettingsPage
                      currentSite={currentSite}
                      user={user}
                      clientSitesList={clientSitesList}
                    />
                  </AmpdPage>
                </RequirePayment>
              }
            />
            <Route
              path={CREATE_CAMPAIGN_PATH}
              element={
                <RequirePayment>
                  <AmpdPage pageName={CREATE_CAMPAIGN_NAME}>
                    <CampaignSetupPage currentSite={currentSite} />
                  </AmpdPage>
                </RequirePayment>
              }
            />
            <Route
              path={COPY_CAMPAIGN_PATH}
              element={
                <RequirePayment>
                  <AmpdPage pageName={COPY_CAMPAIGN_PAGE_NAME}>
                    <CampaignSetupPage
                      currentSite={currentSite}
                      copyCampaign={true}
                    />
                  </AmpdPage>
                </RequirePayment>
              }
            />

            <Route
              path={FACEBOOK_PAGE_V2_PATH}
              element={
                <RequirePayment>
                  <AmpdPage pageName={FACEBOOK_PAGE_V2_NAME}>
                    <FacebookPageV2 />
                  </AmpdPage>
                </RequirePayment>
              }
            />

            {/* FB Page with nested routes for its different tabs */}
            <Route
              path={FACEBOOK_PATH}
              element={
                <RequirePayment>
                  <AmpdPage pageName={FACEBOOK_PAGE_NAME}>
                    <FacebookPage />
                  </AmpdPage>
                </RequirePayment>
              }
            >
              <Route
                index
                element={
                  <Navigate
                    to={`./${FACEBOOK_PERFORMANCE_TAB_PATH}`}
                    replace={true}
                  />
                }
              />
              <Route
                path={FACEBOOK_CONFIGURE_TAB_PATH}
                element={<ConfigureCampaignsTabPane />}
              />
              <Route
                path={FACEBOOK_PERFORMANCE_TAB_PATH}
                element={<AdPerformanceTab />}
              />
            </Route>
          </Route>

          {/* Any route that doesn't have an explicit match will fall back to this */}
          <Route
            path="*"
            element={<Navigate to={defaultRoute} replace={true} />}
          />
        </Routes>
        <ToastContainer
          position="top-center"
          autoClose={false}
          closeOnClick
          draggable
          theme="light"
          limit={3}
        />
      </main>
      <GlobalStyle />
      <PageNoScrollGlobalStyle />
    </ThemeProvider>
  );
}

export default ExtensionV2;
