export type SortOption = "asc" | "desc";

export function stringSort<TData>(
  a: TData,
  b: TData,
  direction: SortOption
): number {
  if (a === "" && b === "") {
    return 0;
  }
  if (a === "") {
    return 1;
  }
  if (b === "") {
    return -1;
  }

  if (typeof a === "string" && typeof b === "string") {
    return direction === "asc" ? a.localeCompare(b) : b.localeCompare(a);
  }

  return 0;
}

export function numberSort<TData>(
  a: TData,
  b: TData,
  direction: SortOption
): number {
  if (typeof a === "number" && typeof b === "number") {
    if (isNaN(a) && isNaN(b)) {
      return 0;
    }
    if (isNaN(a)) {
      return 1;
    }
    if (isNaN(b)) {
      return -1;
    }
    return direction === "asc" ? a - b : b - a;
  }

  return 0;
}
