import { removeNullAndUndefined } from "Common/utils/tsUtils";
import { ampdRed, backgroundLight } from "ExtensionV2/styles/colors";
import _ from "lodash";
import React, { JSX, useState } from "react";
import { Button, Dropdown, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { Column } from "./columns";
import { FilterArguments, MatchOperator } from "./filters";
import { MetricsFilter } from "./MetricsTable";

const StyledFilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;

const StyledFilterPill = styled.div`
  position: relative;
  border-radius: 1rem;
  padding: 0.5rem;
  height: 2lh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  padding: 0 1rem !important;
  border: 1px solid ${ampdRed};
  :hover {
    border: 1px dashed ${ampdRed};
  }

  > p {
    font-style: italic;
    margin: 0;
  }

  :hover {
    .filter-pill-close {
      display: block;
      opacity: 1;
    }

    p {
      opacity: 0.5;
    }
  }

  .filter-pill-close {
    // display: none;
    // position: absolute;
    // right: 0.5rem;
    // top: 0.75rem;
    opacity: 0.7;
    cursor: pointer;
    margin-bottom: 0.25rem;
  }
`;

// This window is absolutely positioned so it can float above the table and
// mimic a dropdown. The width must be calculated based on the length of the column
// name.
const StyledCreateFilterWindow = styled.div<{ labelChars: number }>`
  border: 1px solid ${backgroundLight};
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
  width: ${props => props.labelChars + 55}ch;
  border-radius: 0 1rem 1rem 1rem;
  padding: 1rem;
  position: absolute;
  z-index: 2;
  background-color: white;
  top: 50px;
  z-index: 1000;

  > div {
    gap: 0.5rem;
    align-items: center;
    display: flex;
    flex-direction: row;

    p {
      margin: 0;
      font-size: 1.2rem;
    }
  }

  .number-filter-value-input {
    width: 8rem;
  }
`;

export const FilterManager = <TData,>({
  columns,
  filters,
  onSetFilters
}: {
  columns: Array<Column<TData>>;
  filters: Array<MetricsFilter<TData>>;
  onSetFilters: (filters: Array<MetricsFilter<TData>>) => void;
}): JSX.Element => {
  const [currentlyCreatingFilter, setCurrentlyCreatingFilter] = useState<Column<
    TData
  > | null>(null);

  const [filterDropdownValue, setFilterDropdownValue] = useState<string>("");

  const handleOpenFilterWindow = (column: Column<TData>) => {
    setCurrentlyCreatingFilter(column);
  };

  const handleSaveFilter = (column: Column<TData>, args: FilterArguments) => {
    onSetFilters([...filters, { column, args: args }]);
  };

  const dropdownOptions = columns
    .map(column => {
      if (column.FilterUI == undefined) {
        return;
      }

      return {
        key: String(column.dataName),
        text: column.displayName,
        value: String(column.dataName)
      };
    })
    .filter(removeNullAndUndefined);

  return (
    <>
      <StyledFilterContainer>
        <div style={{ position: "relative" }}>
          <Dropdown
            disabled={currentlyCreatingFilter != null}
            value={filterDropdownValue}
            onChange={(e, { value }) => {
              const column = columns.find(c => String(c.dataName) === value);
              if (column) {
                handleOpenFilterWindow(column);
              }
              setFilterDropdownValue("");
            }}
            button
            className="icon"
            floating
            icon="filter"
            options={dropdownOptions}
            text={" "}
            scrolling
          />

          {currentlyCreatingFilter && (
            <NewFilterWindow
              column={currentlyCreatingFilter}
              onClose={() => setCurrentlyCreatingFilter(null)}
              onSaveFilter={handleSaveFilter}
            />
          )}
        </div>
        {filters.map((filter, i) => {
          return (
            <React.Fragment key={i}>
              <FilterPill
                filter={filter}
                onRemoveFilter={removedFilter => {
                  const newFilters = filters.filter(
                    activeFilter => activeFilter !== removedFilter
                  );
                  onSetFilters(newFilters);
                }}
                key={String(filter.column.dataName)}
              />{" "}
              {i < filters.length - 1 && "AND"}
            </React.Fragment>
          );
        })}
      </StyledFilterContainer>
    </>
  );
};

const NewFilterWindow = <TData,>({
  column,
  onClose,
  onSaveFilter
}: {
  column: Column<TData>;
  onClose: () => void;
  onSaveFilter: (column: Column<TData>, args: FilterArguments) => void;
}) => {
  const [filterArgs, setFilterArgs] = useState<FilterArguments>({});

  return (
    <StyledCreateFilterWindow labelChars={column.displayName.length}>
      <p style={{ fontSize: "1rem", fontStyle: "italic" }}>New filter:</p>
      <div>
        <p style={{ display: "inline" }}>{column.displayName}</p>

        {column.FilterUI != undefined &&
          column.FilterUI(filterArgs, setFilterArgs)}

        <div>
          <Button
            aria-label="Save filter"
            icon="check"
            color="green"
            onClick={() => {
              if (_.isEmpty(filterArgs)) {
                return;
              }

              onSaveFilter(column, filterArgs);
              onClose();
            }}
          />
          <Button
            aria-label="Close filter window"
            icon="close"
            onClick={onClose}
          />
        </div>
      </div>
    </StyledCreateFilterWindow>
  );
};

const FilterPill = <TData,>({
  filter,
  onRemoveFilter
}: {
  filter: MetricsFilter<TData>;
  onRemoveFilter: (filter: MetricsFilter<TData>) => void;
}): JSX.Element => {
  let filterArgsString = "";
  if (filter.args.text != undefined) {
    switch (filter.args.textMatch) {
      case MatchOperator.CONTAINS:
        filterArgsString = `contains "${filter.args.text}"`;
        break;
      case MatchOperator.DOES_NOT_CONTAIN:
        filterArgsString = `does not contain "${filter.args.text}"`;
        break;
      case MatchOperator.STARTS_WITH:
        filterArgsString = `starts with "${filter.args.text}"`;
        break;
      case MatchOperator.ENDS_WITH:
        filterArgsString = `ends with "${filter.args.text}"`;
        break;
      case MatchOperator.EQUALS:
        filterArgsString = `is "${filter.args.text}"`;
        break;
      case MatchOperator.NOT_EQUALS:
        filterArgsString = `is not "${filter.args.text}"`;
        break;
      case MatchOperator.REGEXP:
        filterArgsString = `matches regex "${filter.args.text}"`;
        break;
      default:
        filterArgsString = `contains "${filter.args.text}"`;
    }
  } else if (filter.args.min != undefined && filter.args.max != undefined) {
    if (filter.args.min === filter.args.max) {
      filterArgsString = `= ${filter.args.min}`;
    } else {
      filterArgsString = ` > ${filter.args.min} and < ${filter.args.max}`;
    }
  } else if (filter.args.min != undefined) {
    filterArgsString = ` > ${filter.args.min}`;
  } else if (filter.args.max != undefined) {
    filterArgsString = ` < ${filter.args.max}`;
  } else {
    return <></>;
  }

  return (
    <>
      <StyledFilterPill>
        <p style={{ display: "inline" }}>
          {filter.column.displayName} {filterArgsString}
        </p>
        <Icon
          aria-label="Remove filter"
          className="filter-pill-close"
          name="close"
          onClick={() => {
            onRemoveFilter(filter);
          }}
        />
      </StyledFilterPill>
    </>
  );
};
