import { useSession } from "ExtensionV2/queries/useSession";
import React, { useEffect, useState } from "react";
import { Button, Message } from "semantic-ui-react";
import { extractErrorMessage } from "Common/errors/error";
import AuditSegment from "ExtensionV2/components/AuditSegment";

const FB_APP_ID = "352143924250055";
// This is an ID for an auth config saved in Facebook that is used to authenticate our app as a
// system user to our user's facebook account.
const FB_SYSTEM_USER_AUTH_CONFIG_ID = "355215763943637";

const FB_SDK_VERSION = "v21.0";

// Ref https://developers.facebook.com/docs/facebook-login/web/ for basic SDK implementation advice
// but note that we are using "Facebook Login For Business" which works differently than the
// standard consumer login flow
// https://developers.facebook.com/docs/facebook-login/facebook-login-for-business/
export function FacebookLogin(): JSX.Element {
  const { currentSite, connectDataSourceMutation } = useSession();

  const {
    isLoading: connectDataSourceLoading,
    error: connectDataSourceError,
    mutate: connectDataSource,
    reset: resetConnectDataSource
  } = connectDataSourceMutation;

  const [fbSDKLoaded, setFbSDKLoaded] = useState(false);
  const [fbSDKInitialized, setFbSDKInitialized] = useState(false);
  const [fbSDKError, setFbSDKError] = useState("");

  useEffect(() => {
    const script = document.createElement("script");

    script.id = "facebook-jssdk";
    script.src = "https://connect.facebook.net/en_US/sdk.js";
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    script.onload = () => setFbSDKLoaded(true);

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      setFbSDKLoaded(false);
    };
  }, []);

  useEffect(() => {
    if (!fbSDKLoaded) {
      return;
    }

    window.fbAsyncInit = function() {
      window.FB.init({
        appId: FB_APP_ID,
        xfbml: true,
        version: FB_SDK_VERSION
      });
    };

    setFbSDKInitialized(true);

    return () => {
      setFbSDKInitialized(false);
    };
  }, [fbSDKLoaded]);

  const handleLogin = () => {
    resetConnectDataSource();
    setFbSDKError("");

    window.FB.login(
      response => {
        // "connected" implies success, "unknown" usually means the user closed the popup before
        // completing the login. Anything else implies an unexpected error.
        if (!["connected", "unknown"].includes(response?.status)) {
          setFbSDKError(
            "Unable to login with Facebook. Received login status: " +
              response?.status
          );
          return;
        }

        if (response?.authResponse?.code) {
          connectDataSource({
            siteAlias: currentSite?.siteAlias || "",
            dataSource: { facebook: { code: response.authResponse.code } }
          });
        }
      },
      {
        config_id: FB_SYSTEM_USER_AUTH_CONFIG_ID,
        response_type: "code",
        override_default_response_type: true
      }
    );
  };

  const isLoading =
    connectDataSourceLoading || !fbSDKLoaded || !fbSDKInitialized;

  return (
    <div>
      <>
        {currentSite?.facebookAccount.hasAuthToken ? (
          <AuditSegment
            siteAlias={""}
            icon="check circle"
            color="green"
            title="Connected to your Meta account"
            details={
              <div>
                <p>Available Ad Accounts:</p>
                {currentSite?.facebookAccount?.authorizedAdAccountsList.map(
                  account => {
                    return (
                      <div key={account.id} style={{ marginBottom: "0.5em" }}>
                        {account.id} ({account.name})
                      </div>
                    );
                  }
                )}
              </div>
            }
          />
        ) : (
          <Button
            disabled={isLoading}
            loading={isLoading}
            onClick={handleLogin}
          >
            Login
          </Button>
        )}
        {connectDataSourceError && (
          <Message negative>
            <p>{extractErrorMessage(connectDataSourceError)}</p>
          </Message>
        )}
        {fbSDKError && (
          <Message negative>
            <p>{fbSDKError}</p>
          </Message>
        )}
      </>
    </div>
  );
}
