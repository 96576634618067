import React from "react";
import { Button } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

// Displays notice content above a campaign creation call-to-action button
// Works best within a Flexbox.
export const NoticeLinkButton = props => {
  const { toPath, buttonText } = props;
  const navigate = useNavigate();

  return (
    <>
      {props.children}
      <div style={{ alignSelf: "center" }}>
        <Button color="green" size="large" onClick={() => navigate(toPath)}>
          {buttonText}
        </Button>
      </div>
    </>
  );
};
