import React from "react";
import { generatePath, Navigate, useLocation } from "react-router-dom";
import sanitizeHtml from "sanitize-html";

// Navigates to the specified page under the siteAlias.
export function navigateToPage({ location, siteAlias, toPage, params }) {
  if (!toPage) {
    return;
  }

  let href = generatePath(toPage, { siteAlias }) || location.pathname;
  if (params) {
    const urlParams = new URLSearchParams();
    for (const [key, value] of Object.entries(params)) {
      urlParams.set(key, value);
    }

    const parts = href.split("#");
    parts[0] = parts[0] + "?" + urlParams.toString();
    href = parts.join("#");
  }

  window.location.href = href;
}

// Component that navigates to the toPage using <Redirect>
//
// This is necessary when triggering a redirect from within render().
export function NavigateToPage(props) {
  const { toPage, siteAlias } = props;

  const location = useLocation();

  if (!toPage) {
    return null;
  }

  const href = generatePath(toPage, { siteAlias }) || location.pathname;

  return <Navigate to={href} />;
}

// Removes all html tags from the specified string.
export function fullyScrubHtml(text) {
  if (!text) {
    return text;
  }

  return sanitizeHtml(text, { allowedTags: [], allowedAttributes: {} });
}
