import { isFiniteNumber, XOR } from "Common/utils/tsUtils";
import React, { useState } from "react";
import {
  Dropdown,
  DropdownProps,
  Input,
  InputOnChangeData
} from "semantic-ui-react";

// We will eventually have a stored list of filters (either stored on the site, local storage, or in the URL)
// To make it easier to serialize and deserialize the filters, there is only 1 FilterArguments type that can be narrowed down to a specific filter type.
type TextFilter = { text: string; textMatch?: string };
type NumberFilter = { min?: number; max?: number };
export type FilterArguments = XOR<TextFilter, NumberFilter>;

export const isTextFilter = (args?: FilterArguments): args is TextFilter => {
  return !!args?.text && typeof args.text === "string";
};

export const isNumberFilter = (
  args?: FilterArguments
): args is NumberFilter => {
  return typeof args?.min === "number" || typeof args?.max === "number";
};

export const isFilterArgs = (args?: FilterArguments): args is FilterArguments =>
  isTextFilter(args) || isNumberFilter(args);

/*
  String Filters
*/
export function stringFilterFn<TData>(
  row: TData,
  dataName: keyof TData,
  args: FilterArguments
): boolean {
  if (!isTextFilter(args)) {
    return true;
  }

  const cellData = row[dataName];
  if (typeof cellData !== "string") {
    return true;
  }

  let haystack: string = cellData;
  let needle = args.text;
  if (args.textMatch === MatchOperator.REGEXP) {
    return new RegExp(needle).test(haystack);
  }

  haystack = cellData.trim().toLocaleLowerCase();
  needle = args.text.trim().toLocaleLowerCase();
  const matchOperator = getMatchOperator(args.textMatch);

  switch (matchOperator) {
    case MatchOperator.CONTAINS:
      return haystack.includes(needle);
    case MatchOperator.DOES_NOT_CONTAIN:
      return !haystack.includes(needle);
    case MatchOperator.STARTS_WITH:
      return haystack.startsWith(needle);
    case MatchOperator.ENDS_WITH:
      return haystack.endsWith(needle);
    case MatchOperator.EQUALS:
      return haystack === needle;
    case MatchOperator.NOT_EQUALS:
      return haystack !== needle;
    default:
      return haystack.includes(needle);
  }
}

export enum MatchOperator {
  CONTAINS = "CONTAINS",
  DOES_NOT_CONTAIN = "DOES_NOT_CONTAIN",
  STARTS_WITH = "STARTS_WITH",
  ENDS_WITH = "ENDS_WITH",
  EQUALS = "EQUALS",
  NOT_EQUALS = "NOT_EQUALS",
  REGEXP = "REGEXP"
}

const matchOptions = [
  {
    key: MatchOperator.CONTAINS,
    text: "contains",
    value: MatchOperator.CONTAINS
  },
  {
    key: MatchOperator.DOES_NOT_CONTAIN,
    text: "does not contain",
    value: MatchOperator.DOES_NOT_CONTAIN
  },
  {
    key: MatchOperator.STARTS_WITH,
    text: "starts with",
    value: MatchOperator.STARTS_WITH
  },
  {
    key: MatchOperator.ENDS_WITH,
    text: "ends with",
    value: MatchOperator.ENDS_WITH
  },
  {
    key: MatchOperator.EQUALS,
    text: "equals",
    value: MatchOperator.EQUALS
  },
  {
    key: MatchOperator.NOT_EQUALS,
    text: "does not equal",
    value: MatchOperator.NOT_EQUALS
  },
  {
    key: MatchOperator.REGEXP,
    text: "matches regex",
    value: MatchOperator.REGEXP
  }
];

function getMatchOperator(s?: string) {
  if (!s) {
    return MatchOperator.CONTAINS;
  }

  switch (s.toUpperCase()) {
    case MatchOperator.CONTAINS:
      return MatchOperator.CONTAINS;
    case MatchOperator.DOES_NOT_CONTAIN:
      return MatchOperator.DOES_NOT_CONTAIN;
    case MatchOperator.STARTS_WITH:
      return MatchOperator.STARTS_WITH;
    case MatchOperator.ENDS_WITH:
      return MatchOperator.ENDS_WITH;
    case MatchOperator.EQUALS:
      return MatchOperator.EQUALS;
    case MatchOperator.NOT_EQUALS:
      return MatchOperator.NOT_EQUALS;
    case MatchOperator.REGEXP:
      return MatchOperator.REGEXP;
    default:
      return MatchOperator.CONTAINS;
  }
}

export function StringFilterUI(
  args: FilterArguments,
  setArgs: (args: FilterArguments) => void
): JSX.Element {
  const [selectedOperator, setSelectedOperator] = useState(
    MatchOperator.CONTAINS
  );

  const handleTextChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    setArgs({ text: data.value, textMatch: selectedOperator });
  };

  const handleChangeOperator = (
    _event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    setArgs({});
    setSelectedOperator(data.value as MatchOperator);
  };

  return (
    <div>
      <Dropdown
        compact
        style={{ display: "inline", width: "2rem" }}
        selection
        options={matchOptions}
        value={selectedOperator}
        onChange={handleChangeOperator}
      />{" "}
      <Input
        className="string-filter-value-input"
        onChange={handleTextChange}
      />
    </div>
  );
}

/*
  Number Filters
*/
export function numberFilterFn<TData>(
  row: TData,
  dataName: keyof TData,
  args: FilterArguments
): boolean {
  if (!isNumberFilter(args)) {
    return true;
  }

  const val = row[dataName];
  if (typeof val !== "number") {
    return true;
  }

  const { min, max } = args;
  if (isFiniteNumber(min) && isFiniteNumber(max)) {
    return min < val && val < max;
  }

  if (isFiniteNumber(max)) {
    return val < max;
  }
  if (isFiniteNumber(min)) {
    return min < val;
  }

  return true;
}

enum CompareOperator {
  LESS_THAN = "LESS_THAN",
  GREATER_THAN = "GREATER_THAN",
  EQUAL_TO = "EQUAL_TO",
  BETWEEN = "BETWEEN"
}

const comparatorOptions = [
  {
    key: CompareOperator.LESS_THAN,
    text: "less than",
    value: CompareOperator.LESS_THAN
  },
  {
    key: CompareOperator.GREATER_THAN,
    text: "greater than",
    value: CompareOperator.GREATER_THAN
  },
  {
    key: CompareOperator.EQUAL_TO,
    text: "equal to",
    value: CompareOperator.EQUAL_TO
  },
  {
    key: CompareOperator.BETWEEN,
    text: "between",
    value: CompareOperator.BETWEEN
  }
];

export function NumberFilterUI(
  args: FilterArguments,
  setArgs: (args: FilterArguments) => void
): JSX.Element {
  const [selectedOperator, setSelectedOperator] = useState(
    CompareOperator.LESS_THAN
  );

  const handleChangeOperator = (
    _event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    setArgs({});
    setSelectedOperator(data.value as CompareOperator);
  };

  let inputs = <></>;
  switch (selectedOperator) {
    case CompareOperator.LESS_THAN:
      inputs = <MaxInput args={args} setArgs={setArgs} />;
      break;
    case CompareOperator.GREATER_THAN:
      inputs = <MinInput args={args} setArgs={setArgs} />;
      break;
    case CompareOperator.EQUAL_TO:
      inputs = <EqualInput args={args} setArgs={setArgs} />;
      break;
    case CompareOperator.BETWEEN:
      inputs = (
        <>
          <MinInput args={args} setArgs={setArgs} /> and{" "}
          <MaxInput args={args} setArgs={setArgs} />
        </>
      );
      break;
  }

  return (
    <div>
      <Dropdown
        simple
        compact
        style={{ display: "inline", width: "2rem" }}
        selection
        options={comparatorOptions}
        value={selectedOperator}
        onChange={handleChangeOperator}
      />{" "}
      {inputs}
    </div>
  );
}

function MaxInput({
  args,
  setArgs
}: {
  args: FilterArguments;
  setArgs: (args: FilterArguments) => void;
}) {
  const handleSetMax = (
    _event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    const numVal = Number(data.value);
    if (isNaN(numVal)) {
      return;
    }

    setArgs({
      min: args.min,
      max: numVal
    });
  };

  return (
    <Input
      className="number-filter-value-input"
      type="number"
      onChange={handleSetMax}
    />
  );
}

function MinInput({
  args,
  setArgs
}: {
  args: FilterArguments;
  setArgs: (args: FilterArguments) => void;
}) {
  const handleSetMin = (
    _event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    const numVal = Number(data.value);
    if (isNaN(numVal)) {
      return;
    }

    setArgs({
      max: args.max,
      min: numVal
    });
  };

  return (
    <Input
      className="number-filter-value-input"
      type="number"
      onChange={handleSetMin}
    />
  );
}

function EqualInput({
  setArgs
}: {
  args: FilterArguments;
  setArgs: (args: FilterArguments) => void;
}) {
  const handleSetEqual = (
    _event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    const numVal = Number(data.value);
    if (isNaN(numVal)) {
      return;
    }

    setArgs({
      min: numVal,
      max: numVal
    });
  };

  return (
    <Input
      className="number-filter-value-input"
      type="number"
      onChange={handleSetEqual}
    />
  );
}
