import React, { Suspense, useContext, useEffect } from "react";
import _ from "lodash";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { logout } from "Common/utils/auth";
import { formatCustomerId } from "Common/utils/googleAds";
import { getURLForHubSpotCompany } from "Common/utils/hubspot";

import ampdLogo from "assets/ampd-red-white.svg";
import styled from "styled-components/macro";
import { LoadingSpinner } from "Common/components/LoadingSpinner";
import { Checkbox, Popup } from "semantic-ui-react";
import {
  AmpdChatReserve,
  AmpdContentArea,
  AmpdGrid,
  AmpdHeader,
  AmpdNavBar
} from "ExtensionV2/components/layout/AmpdGridItems";
import {
  AccountsIcon,
  AutomationIcon,
  BrandOverviewIcon,
  CampaignsIcon,
  CreateCampaignsIcon,
  ExportsIcon,
  FacebookIcon,
  HistoryIcon,
  ImpressionsIcon,
  LearnIcon,
  FacebookV2Icon,
  ProductsIcon,
  SettingsIcon
} from "./AmpdSidebarIcons";
import { backgroundDark, backgroundLight } from "ExtensionV2/styles/colors";

import { Site, User } from "ExtensionV2/queries/useSession";
import { SiteManagerLinkClient } from "Common/proto/entity/siteManagerLink_pb";
import { isManagerSite } from "Common/utils/site";
import { WantsDisabledPaywallContext } from "ExtensionV2";
import { needsAmpdPayment } from "Common/utils/ampdPayment";
import { checkIsAmpdProOperator } from "Common/utils/ampdProOperator";
import { useCanUserAddCampaigns } from "Common/utils/siteUIBehavior";
import {
  useShouldShowFacebookPage,
  useWantsInDevelopmentFeatures
} from "Common/utils/featureFlags";

// Allow Ampd Operators to disable the paywall for themselves so they can access
// sites that don't have active billing.
const PaywallSkipper = ({
  user,
  site
}: {
  user: User;
  site: Site;
}): JSX.Element => {
  const [wantsDisabledPaywall, setWantsDisabledPaywall] = useContext(
    WantsDisabledPaywallContext
  );

  if (!checkIsAmpdProOperator(user)) {
    return <></>;
  }

  if (!needsAmpdPayment(site.billingAccountStatus)) {
    return <></>;
  }

  return (
    <StyledPaywallSkipper>
      <p>This site does not have active billing. Skip Paywall?</p>
      <Checkbox
        toggle
        checked={wantsDisabledPaywall}
        onChange={(_ev, data) => setWantsDisabledPaywall(!!data.checked)}
      />
    </StyledPaywallSkipper>
  );
};

const StyledPaywallSkipper = styled.div`
  background-color: orangered;
  width: 100%;
  flex-grow: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 1em;
  padding: 0.5em 0 0 0;
`;

export const AvatarStyled = styled.div<{
  height: string;
  width: string;
  initials: string;
}>`
  height: ${props => props.height};
  width: ${props => props.width};
  border-radius: 999px;
  background: url("https://cdn.auth0.com/avatars/${props =>
    props.initials.toLowerCase()}.png") no-repeat center/cover;
`;

export const FullPageLoadingScreen = (): JSX.Element => (
  <div style={{ height: "100vh" }}>
    <AmpdGrid>
      <AmpdHeader>
        <div style={{ alignSelf: "center", display: "flex" }}>
          <img src={ampdLogo} width={"140px"} alt="Ampd logo" />
        </div>
      </AmpdHeader>
      <AmpdContentArea>
        <LoadingSpinner size="large" loadingMessages={["Loading Ampd"]} />
      </AmpdContentArea>
    </AmpdGrid>
  </div>
);

const FallbackBackground = (): JSX.Element => (
  <div
    style={{
      height: "100%",
      width: "100%",
      backgroundColor: backgroundDark
    }}
  ></div>
);

export const Header = ({
  currentSite,
  user
}: {
  currentSite: Site;
  user: User;
}): JSX.Element => {
  const { siteName, hubSpotCompanyId, adwordsAccounts } = currentSite;
  const { userEmail, userName, isAmpdOperator } = user;
  const googleAdsCustomerId = adwordsAccounts?.[0]?.customerId;

  const navigate = useNavigate();

  let initials = "";
  _.split(userName, " ", 2).forEach(o => {
    initials = initials.concat(o.slice(0, 1));
  });

  let siteNameComponent;
  if (isAmpdOperator) {
    siteNameComponent = (
      <a
        style={{ color: backgroundLight }}
        href={getURLForHubSpotCompany(hubSpotCompanyId, siteName)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {siteName}
      </a>
    );
  }

  return (
    <AmpdHeader>
      <div style={{ alignSelf: "center", display: "flex" }}>
        <img
          src={ampdLogo}
          width={"140px"}
          alt="Ampd logo"
          onClick={() => navigate("/")}
        />
        <div style={{ paddingLeft: "1em", alignSelf: "center" }}>
          <p
            style={{
              display: "inline-block",
              color: backgroundLight,
              margin: 0,
              padding: 0
            }}
          >
            {siteNameComponent} &#183; {userEmail}
            {!!googleAdsCustomerId && (
              <> &#183; {formatCustomerId(googleAdsCustomerId)}</>
            )}
          </p>
        </div>
      </div>

      <div style={{ alignSelf: "center" }}>
        <Popup
          trigger={
            <AvatarStyled initials={initials} height="2.5em" width="2.5em" />
          }
          on="hover"
          content={
            <div onClick={() => logout("")} style={{ cursor: "pointer" }}>
              Sign Out
            </div>
          }
          hoverable
        />
      </div>
    </AmpdHeader>
  );
};

export const AmpdGridRefContext = React.createContext<HTMLDivElement | null>(
  null
);

const AmpdWrapper = ({
  automationPageEnabled,
  googleAdsHistoryEnabled,
  currentSite,
  user,
  clientSitesList,
  brandOverviewPageEnabled
}: {
  automationPageEnabled: boolean;
  googleAdsHistoryEnabled: boolean;
  currentSite: Site;
  user: User;
  clientSitesList: Array<SiteManagerLinkClient.AsObject>;
  brandOverviewPageEnabled: boolean;
}): JSX.Element => {
  const { hasAdwordsAccount, siteAlias } = currentSite;
  const { isCurrentSiteAdmin: isSiteAdmin } = user;
  const canAddCampaigns = useCanUserAddCampaigns();
  const wantsInDevelopmentFeatures = useWantsInDevelopmentFeatures();

  const ampdGridRef = React.useRef<HTMLDivElement>(null);

  const [
    ampdContentArea,
    setAmpdContentArea
  ] = React.useState<HTMLDivElement | null>(null);

  useEffect(() => {
    setAmpdContentArea(ampdGridRef.current);
  }, [ampdGridRef]);

  const { pathname } = useLocation();

  const shouldShowFBPage = useShouldShowFacebookPage();

  // A manager site is one that has client sites or ends with -mgr.  Manager sites can navigate to the
  // AccountsPage.
  const isManager = isManagerSite(currentSite, clientSitesList);

  // Any non-manager site is expected to hook up to a Google Ads account, so even
  // if it isn't currently linked to one, show the campaign related pages.  If
  // a manager site happens to be linked to Google Ads itself, show the campaign
  // related pages, too.  Otherwise, don't expect or encourage manager sites to
  // link to Google Ads directly.
  const isSiteForGoogleAdsCampaigns = !isManager || hasAdwordsAccount;

  const pathSegments = pathname.split("/");
  const isSetupPage = pathSegments[pathSegments.length - 1] === "setup";
  const isWelcomePage = pathSegments[pathSegments.length - 1] === "welcome";
  const activeSidebar = !isSetupPage && !isWelcomePage;

  return (
    <div style={{ height: "100vh" }}>
      <PaywallSkipper user={user} site={currentSite} />
      <AmpdGrid ref={ampdGridRef}>
        {/* header */}
        <Header currentSite={currentSite} user={user} />

        {/* sidebar */}
        {activeSidebar && (
          <AmpdNavBar>
            {/* manager site icons */}
            {isManager && <AccountsIcon siteAlias={siteAlias} />}

            {/* ads campaign site icons */}
            {isSiteForGoogleAdsCampaigns && (
              <>
                {/* general icons */}
                {brandOverviewPageEnabled && (
                  <BrandOverviewIcon siteAlias={siteAlias} />
                )}
                <CampaignsIcon siteAlias={siteAlias} />
                {canAddCampaigns && (
                  <CreateCampaignsIcon siteAlias={siteAlias} />
                )}
                <ImpressionsIcon siteAlias={siteAlias} />
                <ProductsIcon siteAlias={siteAlias} />

                {automationPageEnabled && (
                  <AutomationIcon siteAlias={siteAlias} />
                )}

                {/* admin icons */}
                <ExportsIcon siteAlias={siteAlias} isSiteAdmin={isSiteAdmin} />

                {/* custom icons */}
                {googleAdsHistoryEnabled && (
                  <HistoryIcon siteAlias={siteAlias} />
                )}

                {/* experimental icons */}
                {shouldShowFBPage && <FacebookIcon siteAlias={siteAlias} />}

                {wantsInDevelopmentFeatures && (
                  <FacebookV2Icon siteAlias={siteAlias} />
                )}
              </>
            )}

            {/* general site icons */}
            <LearnIcon siteAlias={siteAlias} />
            <SettingsIcon siteAlias={siteAlias} />
          </AmpdNavBar>
        )}

        {/* chat space */}
        <AmpdChatReserve></AmpdChatReserve>

        {/* content */}
        <AmpdContentArea>
          <AmpdGridRefContext.Provider value={ampdContentArea}>
            <Suspense fallback={<FallbackBackground />}>
              <Outlet />
            </Suspense>
          </AmpdGridRefContext.Provider>
        </AmpdContentArea>
      </AmpdGrid>
    </div>
  );
};

export default AmpdWrapper;
