import _ from "lodash";

import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, Icon, Label, Message, Popup } from "semantic-ui-react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";

// grpc-web
import * as proto from "Common/utils/proto";
import { GRPCWebClient } from "Common/utils/grpc";
import {
  UpdateCampaignsRequest,
  UpdateCampaignAction
} from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import {
  UpdateCampaignStatusAction,
  CampaignStatus
} from "Common/proto/googleAdsPb/campaign_pb";
import { sendGAEvent } from "./GA";

import CampaignBudgetEditButton from "./CampaignBudgetEditButton";

import {
  STATUS_ENABLED_STR,
  STATUS_PAUSED_STR,
  STATUS_REMOVED_STR,
  COLUMN_DATA_KEYS,
  formatMetricColumnValue,
  getMetricColumnAnnotation,
  getMetricColumnCompareInfo,
  renderFormattedValue
} from "./MetricColumns";
import { BIDDING_STRATEGY_TARGET_SPEND_STR } from "../queries/useUpdateCampaignBiddingStrategy";

import { formatMetric } from "Common/utils/metrics";
import { getCurrencyMetricDef } from "Common/utils/money";

import CampaignsTableKeywordRow from "./CampaignsTableKeywordRow";
import CampaignsTableMenuRow from "./CampaignsTableMenuRow";
import {
  AMPD_SELECTED_ROW_HEAD_CLASS,
  DataTableFreezeLeftCell,
  DataTableMetricCell,
  DataTableRowCell,
  SelectableDataTableRow
} from "./AmpdDataTable";
import {
  CAMPAIGN_DASHBOARD_GA_CATEGORY,
  CAMPAIGN_ID_QUERY_PARAM,
  MARKETPLACE_QUERY_PARAM
} from "ExtensionV2/ExtensionV2";
import { tableHeader } from "ExtensionV2/styles/zIndexes";
import CampaignBiddingStrategyCellEditor from "./CampaignBiddingStrategyCellEditor";
import {
  useCampaignStatusHistory,
  useCampaignBudgetHistory
} from "../queries/useChangeHistory";
import { EditIconWithHistory } from "./EditIconWithHistory";
import {
  BidAutomationModal,
  UPGRADE_FOR_BID_AUTOMATION_TOOLTIP
} from "./campaignEditor/BidAutomationEditButton";
import { AutomationStatus } from "../queries/useItemizedCampaignConfiguration";
import {
  useHasAmpdBidAutomationFeature,
  useHasSelfServeBilling
} from "Common/utils/featureFlags";
import { useSessionUser } from "../queries/useSessionUser";
import { UpgradeButton } from "../pages/SettingsPage/SettingsPage";
import SimpleTooltip from "./SimpleTooltip";
import CampaignsTableImpactedProductRow from "./CampaignsTableImpactedProductRow";
import {
  useCanUserEditBids,
  useCanUserEditBudgets,
  useCanUserEditStatuses
} from "../../Common/utils/siteUIBehavior";

const CampaignNameCellFlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ActiveCampaignName = styled.div`
  align-self: center;
  margin-left: 0.5em;
  padding-left: 0.5em;
  text-indent: -0.5em;
`;

export const InactiveCampaignName = styled(ActiveCampaignName)`
  font-family: inherit;
  font-style: italic;
  opacity: 0.5;
`;

const ExpandDetails = styled.div`
  align-self: center;
  margin-left: 0.1em;
  margin-right: -10px;
  font-size: small;
  p {
    display: inline;
    font-weight: ${props => (props.active ? "bold" : "normal")};
    font-style: italic;
    margin-right: 0;
    white-space: nowrap;
  }
`;

export const CampaignNameCell = styled(DataTableFreezeLeftCell)`
  z-index: ${tableHeader - 1};
  width: 35em;
  min-width: 35em;
  &:hover {
    ${ExpandDetails} {
      p {
        text-decoration: underline;
      }
    }
  }
`;

const CampaignWarningCell = styled(DataTableRowCell)`
  max-width: 1em;
  text-align: center !important;
`;

export const KeywordTextFlexWrapper = styled(CampaignNameCellFlexWrapper)`
  justify-content: flex-start;
`;

export const KeywordTextCell = styled(DataTableFreezeLeftCell)`
  width: 35em;
  min-width: 35em;
  cursor: auto;
  margin-left: 3em;
`;

export const ActiveKeywordText = styled(ActiveCampaignName)``;
export const InactiveKeywordText = styled(InactiveCampaignName)``;

export const NotApplicableCell = styled(DataTableRowCell)`
  &&& {
    p {
      text-align: center;
      font-style: italic;
      font-size: smaller;
    }
  }
`;

export const EditDropdown = styled(Dropdown)`
  &&& {
    padding-left: 0;
    padding-right: 0;
    border: none;
    background-color: inherit;
    white-space: nowrap;
    &[aria-expanded="true"] {
      z-index: ${tableHeader + 1};
    }
    .menu {
      left: auto;
      right: 0;
      border-top: 1px solid #96c8da !important;
      border-radius: 0.28571429rem 0 0.28571429rem 0.28571429rem;
      width: max-content !important;
    }
  }
`;

export const EditFieldLabel = styled.p`
  display: block;
  margin: 0 0 0.3em 0.5em;
  font-weight: bold;
`;

export const EditIcon = styled(Icon)`
  &&& {
    margin-left: 0.5em;
  }
`;

const BudgetAmountWrapper = styled.div`
  &&& {
    pointer-events: ${props => (props.isLoading ? "none" : "")};
    opacity: ${props => (props.isLoading ? "0.3" : "")};
    display: flex;
    justify-content: center;
    align-content: center;
    p {
      margin: 0;
      margin-right: 0.5em;
    }
    i {
      margin: 0 !important;
    }
  }
`;

const BidAutomationStatus = styled(Label)`
  &&& {
    padding: 0.5em;
    min-width: 9em;
  }
`;

const CampaignStatusCellContent = ({
  siteAlias,
  campaignId,
  campaignStatus,
  googleAdsAccountId,
  setWillChange,
  refetchCampaignConfiguration
}) => {
  const canEditStatuses = useCanUserEditStatuses();

  const [selectedCampaignStatus, setSelectedCampaignStatus] = useState(
    campaignStatus
  );
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);

  // Don't load history items unless the history popup is open.
  const historyItems = useCampaignStatusHistory(
    siteAlias,
    historyOpen ? campaignId : ""
  );

  const handleUpdateCampaignStatus = async (_ev, { value: status }) => {
    if (!siteAlias || !campaignId || !googleAdsAccountId) {
      return;
    }

    sendGAEvent(
      CAMPAIGN_DASHBOARD_GA_CATEGORY,
      "Update Campaign",
      siteAlias,
      status === STATUS_ENABLED_STR ? "enable" : "pause"
    );

    const updateReq = proto.set(new UpdateCampaignsRequest(), {
      siteAlias,
      customerId: googleAdsAccountId,
      actions: [
        proto.set(new UpdateCampaignAction(), {
          updateStatus: proto.set(new UpdateCampaignStatusAction(), {
            campaignId: campaignId,
            newStatus:
              status === STATUS_ENABLED_STR
                ? CampaignStatus.Option.ENABLED
                : CampaignStatus.Option.PAUSED
          })
        })
      ]
    });

    try {
      setIsLoading(true);
      if (setWillChange) {
        setWillChange(true);
      }

      await GRPCWebClient.updateCampaigns(updateReq);
      setSelectedCampaignStatus(status);

      if (refetchCampaignConfiguration) {
        await refetchCampaignConfiguration(campaignId);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);

      if (setWillChange) {
        setWillChange(false);
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignContent: "center"
      }}
    >
      {campaignStatus === STATUS_REMOVED_STR ? (
        <EditDropdown
          compact
          icon={null}
          disabled={true}
          options={[
            {
              key: STATUS_REMOVED_STR,
              text: "Removed",
              value: STATUS_REMOVED_STR
            }
          ]}
          value={STATUS_REMOVED_STR}
        />
      ) : (
        <EditDropdown
          compact
          open={dropdownOpen}
          onOpen={() => setDropdownOpen(canEditStatuses)}
          onClose={() => setDropdownOpen(false)}
          icon={
            <EditIconWithHistory
              disabled={dropdownOpen}
              readonly={!canEditStatuses}
              historyOpen={historyOpen}
              setHistoryOpen={setHistoryOpen}
              historyItems={historyItems}
            />
          }
          disabled={isLoading}
          selection
          options={[
            {
              key: STATUS_ENABLED_STR,
              text: "Enabled",
              value: STATUS_ENABLED_STR
            },
            {
              key: STATUS_PAUSED_STR,
              text: "Paused",
              value: STATUS_PAUSED_STR
            }
          ]}
          value={selectedCampaignStatus}
          onChange={handleUpdateCampaignStatus}
        />
      )}
    </div>
  );
};

const CampaignBudgetAmountCellContent = ({
  itemizedCampaignConfiguration,
  campaignId,
  currencyCode,
  formatAmount,
  googleAdsAccountId,
  refetchCampaignConfiguration,
  siteAlias
}) => {
  const canEditBudgets = useCanUserEditBudgets();

  const {
    campaignBudget,
    campaignBudgetId,
    campaignBudgetIsShared
  } = itemizedCampaignConfiguration;

  const [budgetAmount, setBudgetAmount] = useState(campaignBudget);
  const [isLoading, setIsLoading] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);

  // Don't load history items unless the history popup is open.
  const historyItems = useCampaignBudgetHistory(
    siteAlias,
    historyOpen ? campaignId : "",
    currencyCode
  );

  useEffect(() => {
    // If the budget was reloaded, reflect that new amount.
    if (budgetAmount !== campaignBudget) {
      setBudgetAmount(campaignBudget);
    }
  }, [budgetAmount, campaignBudget]);

  const campaign = {
    customerId: googleAdsAccountId,
    campaignId
  };
  return (
    <BudgetAmountWrapper isLoading={isLoading}>
      <p>{formatAmount(budgetAmount)}</p>

      <CampaignBudgetEditButton
        readonly={!canEditBudgets}
        icon={
          <EditIconWithHistory
            readonly={!canEditBudgets}
            disabled={false}
            historyOpen={historyOpen}
            setHistoryOpen={setHistoryOpen}
            historyItems={historyItems}
          />
        }
        siteAlias={siteAlias}
        gaCategory={CAMPAIGN_DASHBOARD_GA_CATEGORY}
        campaign={campaign}
        campaignBudgetId={String(campaignBudgetId)}
        currencyCode={currencyCode}
        budget={budgetAmount}
        budgetIsShared={campaignBudgetIsShared}
        refetchCampaignConfiguration={refetchCampaignConfiguration}
        onSubmit={() => setIsLoading(true)}
        onComplete={newAmount => {
          setIsLoading(false);
          setBudgetAmount(newAmount);
        }}
        onFailure={() => {
          setIsLoading(false);
          setBudgetAmount(campaignBudget);
        }}
      />
    </BudgetAmountWrapper>
  );
};

const CampaignsTableRow = ({
  campaignObject,
  columns,
  costCurrencyCode,
  disabled,
  expandedCampaignId,
  googleAdsAccountId,
  sampleKeywordsSortState,
  setMaxSampleKeywords,
  enabledKeywordCount,
  pausedKeywordCount,
  sampleKeywordRows,
  allKeywords,
  maxImpactedProducts,
  impactedProductsSortState,
  impactedProductsTableFlags,
  setImpactedProductsTableFlags,
  setMaxImpactedProducts,
  showNonBrandProducts,
  setShowNonBrandProducts,
  impactedProductRows,
  allImpactedProducts,
  impactedProductsLoading,
  keywordsLoading,
  disabledCriteriaIds,
  refetchCampaignKeyword,
  revenueCurrencyCode,
  rowIndex,
  setExpandedCampaignId,
  setShowKeywordsForCampaignId,
  showFractions,
  showKeywordsForCampaignId,
  showUnconvertedRevenue,
  siteAlias,
  campaignConfigurationsByCampaignId,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration,
  refreshMetrics
}) => {
  const { id: campaignId } = campaignObject;
  const { marketplaceInfo } = itemizedCampaignConfiguration;
  const isEnabledCampaign =
    campaignObject[COLUMN_DATA_KEYS.status] === STATUS_ENABLED_STR;
  const isRemovedCampaign =
    campaignObject[COLUMN_DATA_KEYS.status] === STATUS_REMOVED_STR;

  const [mouseDownTime, setMouseDownTime] = useState(null);
  const isExpanded = expandedCampaignId === campaignObject.id;
  const isSelectedCampaign = showKeywordsForCampaignId === campaignId;
  const isShowingKeywords = isSelectedCampaign && !isRemovedCampaign;

  const [searchParams, setSearchParams] = useSearchParams();

  const [willChange, setWillChange] = useState(false);

  const [isOver, setIsOver] = useState(false);
  const [, setDelayTimerId] = useState(null);

  const toggleEditTray = () => {
    setExpandedCampaignId(isExpanded ? null : campaignId);
  };

  // If the editor tray is already open and the user is moving up and down the
  // list of campaign names, use a little bit of delay to shift the focus of the
  // campaign editor to this campaign.  Without the delay, the UI will spend too many cycles
  // re-rendering for uninteresting fly-over campaigns.
  const enterName = () => {
    setIsOver(true);
    if (expandedCampaignId && expandedCampaignId !== campaignId) {
      const timerId = _.delay(() => {
        setExpandedCampaignId(campaignId);
        setDelayTimerId(null);
      }, 100);
      setDelayTimerId(timerId);
    }
  };

  const leaveName = () => {
    setIsOver(false);
    setDelayTimerId(timerId => {
      if (timerId) {
        clearTimeout(timerId);
      }
      return null;
    });
  };

  const handleSetSelectedCampaignId = (id, marketplaceInfo) => {
    if (!id) {
      searchParams.delete(CAMPAIGN_ID_QUERY_PARAM);
    } else {
      searchParams.set(CAMPAIGN_ID_QUERY_PARAM, id);
    }

    if (marketplaceInfo?.domain) {
      searchParams.set(MARKETPLACE_QUERY_PARAM, marketplaceInfo.domain);
    }

    setSearchParams(searchParams);
  };

  const keywordRowComponents = useMemo(() => {
    if (isRemovedCampaign) {
      return [];
    }
    let rows = [];
    if (maxImpactedProducts > 0) {
      rows = (impactedProductRows || []).map((productObject, productIndex) => {
        const isLastRow = productIndex === impactedProductRows.length - 1;
        const isFocalRow =
          impactedProductRows.length < 4 ? isLastRow : productIndex === 3;

        return (
          <CampaignsTableImpactedProductRow
            itemizedCampaignConfiguration={itemizedCampaignConfiguration}
            columns={columns}
            costCurrencyCode={costCurrencyCode}
            googleAdsAccountId={googleAdsAccountId}
            isFocalRow={isFocalRow}
            isLastRow={isLastRow}
            key={productObject.id}
            impactedProductsTableFlags={impactedProductsTableFlags}
            impactedProduct={productObject}
            refetchCampaignConfiguration={refetchCampaignConfiguration}
            revenueCurrencyCode={revenueCurrencyCode}
            rowIndex={rowIndex}
            showFractions={showFractions}
            showUnconvertedRevenue={showUnconvertedRevenue}
            siteAlias={siteAlias}
            willChange={willChange}
            setWillChange={setWillChange}
            refreshMetrics={refreshMetrics}
          />
        );
      });
    } else {
      rows = (sampleKeywordRows || []).map((keywordObject, keywordIndex) => {
        const isLastRow = keywordIndex === sampleKeywordRows.length - 1;
        const isFocalRow =
          sampleKeywordRows.length < 4 ? isLastRow : keywordIndex === 3;

        return (
          <CampaignsTableKeywordRow
            campaignId={campaignId}
            columns={columns}
            costCurrencyCode={costCurrencyCode}
            googleAdsAccountId={googleAdsAccountId}
            isFocalRow={isFocalRow}
            isLastRow={isLastRow}
            key={keywordObject.id}
            keywordObject={keywordObject}
            revenueCurrencyCode={revenueCurrencyCode}
            rowIndex={rowIndex}
            showFractions={showFractions}
            showUnconvertedRevenue={showUnconvertedRevenue}
            siteAlias={siteAlias}
            disabled={disabledCriteriaIds.has(keywordObject.criteriaId)}
            refetchCampaignKeyword={refetchCampaignKeyword}
          />
        );
      });
    }

    return (
      <>
        <CampaignsTableMenuRow
          siteAlias={siteAlias}
          customerId={campaignObject.customerId}
          campaignId={campaignObject.id}
          columns={columns}
          allKeywords={allKeywords}
          keywordsLoading={keywordsLoading}
          rowIndex={rowIndex}
          sampleKeywordRows={sampleKeywordRows}
          enabledKeywordCount={enabledKeywordCount}
          pausedKeywordCount={pausedKeywordCount}
          sampleKeywordsSortState={sampleKeywordsSortState}
          setMaxSampleKeywords={setMaxSampleKeywords}
          maxImpactedProducts={maxImpactedProducts}
          impactedProductsSortState={impactedProductsSortState}
          impactedProductsTableFlags={impactedProductsTableFlags}
          setImpactedProductsTableFlags={setImpactedProductsTableFlags}
          setMaxImpactedProducts={setMaxImpactedProducts}
          showNonBrandProducts={showNonBrandProducts}
          setShowNonBrandProducts={setShowNonBrandProducts}
          impactedProductsLoading={impactedProductsLoading}
          impactedProductCount={allImpactedProducts?.length || 0}
          sampleImpactedProductCount={impactedProductRows?.length || 0}
          costCurrencyCode={costCurrencyCode}
          refetchCampaignKeyword={refetchCampaignKeyword}
          itemizedCampaignConfiguration={itemizedCampaignConfiguration}
        />
        {rows}
      </>
    );
  }, [
    campaignId,
    itemizedCampaignConfiguration,
    campaignObject,
    isRemovedCampaign,
    columns,
    costCurrencyCode,
    googleAdsAccountId,
    enabledKeywordCount,
    pausedKeywordCount,
    allKeywords,
    keywordsLoading,
    sampleKeywordRows,
    revenueCurrencyCode,
    rowIndex,
    showFractions,
    showUnconvertedRevenue,
    sampleKeywordsSortState,
    setMaxSampleKeywords,
    maxImpactedProducts,
    impactedProductsSortState,
    impactedProductsTableFlags,
    setImpactedProductsTableFlags,
    setMaxImpactedProducts,
    showNonBrandProducts,
    setShowNonBrandProducts,
    impactedProductsLoading,
    impactedProductRows,
    allImpactedProducts,
    siteAlias,
    disabledCriteriaIds,
    refetchCampaignKeyword,
    refetchCampaignConfiguration,
    willChange,
    refreshMetrics
  ]);

  return (
    <React.Fragment key={`${campaignId}-row`}>
      <SelectableDataTableRow
        disabled={disabled || willChange}
        isSelected={(isSelectedCampaign && !expandedCampaignId) || isExpanded}
        keywordsOpen={isShowingKeywords}
      >
        {(columns || []).map((columnName, i) => {
          let key = `${campaignId}-cell-${i}`;
          let formattedValue;
          switch (columnName) {
            case COLUMN_DATA_KEYS.criticalInformation:
              return (
                <CampaignWarningCell key={columnName} rowIndex={rowIndex}>
                  {!!campaignObject[COLUMN_DATA_KEYS.criticalInformation] && (
                    <Icon name="warning sign" color="yellow" />
                  )}
                </CampaignWarningCell>
              );
            case COLUMN_DATA_KEYS.bidAutomationStatus: {
              const statusInfo =
                campaignObject[COLUMN_DATA_KEYS.bidAutomationStatus];

              return (
                <DataTableRowCell key={key} rowIndex={rowIndex}>
                  {!!statusInfo && (
                    <BidAutomationCell
                      siteAlias={siteAlias}
                      itemizedCampaignConfiguration={
                        itemizedCampaignConfiguration
                      }
                      refetchCampaignConfiguration={
                        refetchCampaignConfiguration
                      }
                      campaignConfigurationsByCampaignId={
                        campaignConfigurationsByCampaignId
                      }
                      statusInfo={statusInfo}
                    />
                  )}
                </DataTableRowCell>
              );
            }
            case COLUMN_DATA_KEYS.campaignName:
              return (
                <CampaignNameCell
                  key={key}
                  rowIndex={rowIndex}
                  onMouseEnter={() => {
                    enterName();
                  }}
                  onMouseLeave={() => {
                    leaveName();
                  }}
                >
                  <CampaignNameCellFlexWrapper
                    style={{
                      // If there is no critical info to show, make the whole vertical row
                      // space clickable.
                      height: campaignObject[
                        COLUMN_DATA_KEYS.criticalInformation
                      ]
                        ? undefined
                        : "100%"
                    }}
                    onMouseDown={() => {
                      setMouseDownTime(Date.now());
                    }}
                    onMouseUp={() => {
                      // Customers may try to drag on the campaign name so they can copy it to their
                      // clipboard. This mouseup/mousedown tries to account for that by not navigating
                      // on when a long click is detected.
                      if (Date.now() - mouseDownTime > 1000) {
                        return;
                      }
                      setShowKeywordsForCampaignId(
                        isSelectedCampaign || isRemovedCampaign
                          ? null
                          : campaignId
                      );
                      handleSetSelectedCampaignId(
                        isSelectedCampaign ? null : campaignId,
                        marketplaceInfo
                      );
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {!isRemovedCampaign && (
                        <Icon
                          className={
                            isShowingKeywords
                              ? AMPD_SELECTED_ROW_HEAD_CLASS
                              : undefined
                          }
                          name={
                            isShowingKeywords ? "angle down" : "angle right"
                          }
                          style={{ margin: "auto" }}
                        />
                      )}
                      {isEnabledCampaign ? (
                        <ActiveCampaignName>
                          {campaignObject[COLUMN_DATA_KEYS.campaignName]}
                        </ActiveCampaignName>
                      ) : (
                        <InactiveCampaignName>
                          {campaignObject[COLUMN_DATA_KEYS.campaignName]}
                        </InactiveCampaignName>
                      )}
                    </div>
                    {((isOver && !expandedCampaignId) || isExpanded) && (
                      <ExpandDetails
                        rowIndex={rowIndex}
                        active={isExpanded}
                        onClick={ev => {
                          ev.stopPropagation();
                          toggleEditTray();
                        }}
                        onMouseDown={ev => {
                          ev.stopPropagation();
                        }}
                        onMouseUp={ev => {
                          ev.stopPropagation();
                        }}
                      >
                        <p>
                          Details
                          <Icon
                            name={
                              isExpanded
                                ? "angle double right"
                                : "angle double left"
                            }
                          />
                        </p>
                      </ExpandDetails>
                    )}
                  </CampaignNameCellFlexWrapper>
                  {campaignObject[COLUMN_DATA_KEYS.criticalInformation] && (
                    <CampaignNameCellFlexWrapper>
                      {campaignObject[COLUMN_DATA_KEYS.criticalInformation]}
                    </CampaignNameCellFlexWrapper>
                  )}
                </CampaignNameCell>
              );
            case COLUMN_DATA_KEYS.status:
              return (
                <DataTableRowCell key={key} rowIndex={rowIndex}>
                  <CampaignStatusCellContent
                    siteAlias={siteAlias}
                    campaignId={campaignId}
                    campaignStatus={campaignObject[COLUMN_DATA_KEYS.status]}
                    googleAdsAccountId={googleAdsAccountId}
                    setWillChange={setWillChange}
                    refetchCampaignConfiguration={refetchCampaignConfiguration}
                  />
                </DataTableRowCell>
              );

            case COLUMN_DATA_KEYS.cpcBid: {
              const biddingStrategy =
                campaignObject[COLUMN_DATA_KEYS.biddingStrategy];

              let value = campaignObject[columnName];
              if (biddingStrategy === BIDDING_STRATEGY_TARGET_SPEND_STR) {
                value = campaignObject[COLUMN_DATA_KEYS.effectiveCpcBid];
              }

              let formattedValue = String(
                formatMetricColumnValue(
                  columnName,
                  value,
                  costCurrencyCode,
                  revenueCurrencyCode,
                  true
                )
              );
              if (biddingStrategy === BIDDING_STRATEGY_TARGET_SPEND_STR) {
                if (value === 0) {
                  formattedValue = "(unset)";
                } else {
                  formattedValue = (
                    <span>
                      <small>Max:</small> {formattedValue}
                    </span>
                  );
                }
              } else {
                formattedValue = (
                  <span>
                    <small>Default:</small> {formattedValue}
                  </span>
                );
              }

              return (
                <DataTableMetricCell key={columnName} rowIndex={rowIndex}>
                  <CampaignBiddingStrategyCellEditor
                    key={`${biddingStrategy}:${value}`}
                    disabled={disabled || willChange}
                    siteAlias={siteAlias}
                    campaignId={campaignId}
                    adGroupId={campaignObject[COLUMN_DATA_KEYS.adGroupId]}
                    biddingStrategy={biddingStrategy}
                    cpcBid={value}
                    formattedCpcBid={formattedValue}
                    currencyCode={costCurrencyCode}
                    googleAdsAccountId={googleAdsAccountId}
                    setWillChange={setWillChange}
                    refetchCampaignConfiguration={refetchCampaignConfiguration}
                    refetchCampaignKeyword={refetchCampaignKeyword}
                  />
                </DataTableMetricCell>
              );
            }

            case COLUMN_DATA_KEYS.dailyBudget:
              return (
                <DataTableRowCell key={key} rowIndex={rowIndex}>
                  <CampaignBudgetAmountCellContent
                    itemizedCampaignConfiguration={
                      itemizedCampaignConfiguration
                    }
                    campaignId={campaignId}
                    currencyCode={costCurrencyCode}
                    formatAmount={amount =>
                      formatMetric(
                        getCurrencyMetricDef(costCurrencyCode, showFractions),
                        amount
                      )
                    }
                    googleAdsAccountId={googleAdsAccountId}
                    refetchCampaignConfiguration={refetchCampaignConfiguration}
                    siteAlias={siteAlias}
                  />
                </DataTableRowCell>
              );

            case COLUMN_DATA_KEYS.adTarget:
              return (
                <DataTableRowCell
                  key={key}
                  rowIndex={rowIndex}
                  style={{ textAlign: "center" }}
                >
                  {campaignObject[COLUMN_DATA_KEYS.adTarget] == null ? (
                    "--"
                  ) : (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={itemizedCampaignConfiguration.finalURL}
                    >
                      {campaignObject[COLUMN_DATA_KEYS.adTarget]}
                    </a>
                  )}
                </DataTableRowCell>
              );
            case COLUMN_DATA_KEYS.campaignStartDate:
              return (
                <DataTableRowCell
                  key={key}
                  rowIndex={rowIndex}
                  style={{ whiteSpace: "nowrap", textAlign: "center" }}
                >
                  {campaignObject[COLUMN_DATA_KEYS.campaignStartDate]}
                </DataTableRowCell>
              );

            default: {
              formattedValue = formatMetricColumnValue(
                columnName,
                campaignObject[columnName],
                costCurrencyCode,
                revenueCurrencyCode,
                showFractions
              );

              let annotationValue;
              if (columnName === COLUMN_DATA_KEYS.newToBrandRevenuePercentage) {
                annotationValue = getMetricColumnAnnotation(
                  columnName,
                  campaignObject,
                  revenueCurrencyCode,
                  showFractions,
                  showUnconvertedRevenue
                );
              } else {
                annotationValue = getMetricColumnAnnotation(
                  columnName,
                  campaignObject,
                  campaignObject[
                    COLUMN_DATA_KEYS.unconvertedRevenueCurrencyCode
                  ],
                  showFractions,
                  showUnconvertedRevenue
                );
              }

              // If we want to show comparison annotations, we need to compile
              // some information based on any compareMetrics value stored in
              // the data object.
              const compareInfo = getMetricColumnCompareInfo(
                columnName,
                campaignObject,
                costCurrencyCode,
                revenueCurrencyCode
              );

              return (
                <DataTableMetricCell key={key} rowIndex={rowIndex}>
                  {renderFormattedValue(
                    formattedValue,
                    annotationValue,
                    compareInfo
                  )}
                </DataTableMetricCell>
              );
            }
          }
        })}
      </SelectableDataTableRow>

      {isShowingKeywords && keywordRowComponents}
    </React.Fragment>
  );
};

const BidAutomationCell = ({
  siteAlias,
  itemizedCampaignConfiguration,
  refetchCampaignConfiguration,
  campaignConfigurationsByCampaignId,
  statusInfo
}) => {
  const canEditBids = useCanUserEditBids();

  const [bidAutomationModalOpen, setBidAutomationModalOpen] = useState(false);
  const hasAmpdBidAutomationFeature = useHasAmpdBidAutomationFeature();
  const hasSelfServeBilling = useHasSelfServeBilling();
  const { isCurrentSiteAdmin } = useSessionUser();

  const { bidAutomationStatus } = itemizedCampaignConfiguration;

  const handleBidAutomationModalClose = () => {
    setBidAutomationModalOpen(false);
  };
  const campaignIds = useMemo(
    () => Array.from(campaignConfigurationsByCampaignId?.keys() || []),
    [campaignConfigurationsByCampaignId]
  );

  const handleBidAutomationModalOpen =
    hasAmpdBidAutomationFeature && canEditBids
      ? () => {
          sendGAEvent(
            CAMPAIGN_DASHBOARD_GA_CATEGORY,
            `Click Edit Ampd Bid Automation`,
            siteAlias
          );
          setBidAutomationModalOpen(true);
        }
      : undefined;

  if (
    !hasAmpdBidAutomationFeature &&
    isCurrentSiteAdmin &&
    hasSelfServeBilling &&
    bidAutomationStatus !== AutomationStatus.ENABLED
  ) {
    return (
      <SimpleTooltip tooltip={UPGRADE_FOR_BID_AUTOMATION_TOOLTIP}>
        <div>
          <UpgradeButton
            siteAlias={siteAlias}
            label="Upgrade"
            compact={true}
            gaLocation="Bid Automation column"
          />
        </div>
      </SimpleTooltip>
    );
  }

  return (
    <>
      <Popup
        animation="none"
        position={"bottom center"}
        on={"hover"}
        wide="very"
        hoverable={false}
        disabled={!statusInfo.statusMessage}
        trigger={
          <div onClick={handleBidAutomationModalOpen}>
            <BidAutomationStatus
              style={{ marginLeft: "1em" }}
              basic
              size="small"
              color={statusInfo.statusColor}
            >
              {statusInfo.statusText}
            </BidAutomationStatus>
          </div>
        }
      >
        <Message style={{ margin: 0 }} color={statusInfo.statusColor}>
          <p>{statusInfo.statusMessage}</p>
        </Message>
      </Popup>
      {bidAutomationModalOpen && (
        <BidAutomationModal
          siteAlias={siteAlias}
          gaCategory={CAMPAIGN_DASHBOARD_GA_CATEGORY}
          itemizedCampaignConfiguration={itemizedCampaignConfiguration}
          refetchCampaignConfiguration={refetchCampaignConfiguration}
          campaignIds={campaignIds}
          onClose={handleBidAutomationModalClose}
        />
      )}
    </>
  );
};

export default CampaignsTableRow;
