// This is a Typescript-compatible null/undefined items filter function.
// Typescript is unable to infer that .filter(Boolean) is a type guard against
// nullish things, so we have to annotate it so that it can be confident, else
// it will still want an "| null" attached to your array types that have been
// null-filtered.
export function removeNullAndUndefined<TValue>(
  value: TValue | null | undefined
): value is TValue {
  if (value === null || value === undefined) return false;

  // https://stackoverflow.com/a/46700791/3395069
  const typeCheckedValue: TValue = value;
  return typeCheckedValue !== null; // avoid typescript unused const lint error
}

export type None = null | undefined;

// Lifted from  https://stackoverflow.com/a/53229567
type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };

export type XOR<T, U> = T | U extends Record<string, unknown>
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U;

// isFinite only accepts values known to be a number, and Number.isFinite
// accepts unknown values so it can't be used as a type guard, so use this if
// you need to tell typescript that a value is a finite number.
// isFiniteNumber(5) => true
// isFiniteNumber(Infinity) => false
// isFiniteNumber(NaN) => false
// isFiniteNumber("5") => false
export function isFiniteNumber(value: unknown): value is number {
  return typeof value === "number" && isFinite(value);
}
