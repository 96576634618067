import React, {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import _ from "lodash";
import Immutable from "immutable";
import { useSearchParams } from "react-router-dom";
import { useCampaignConfigurationsByCampaignId } from "../../queries/useCampaignConfigurationsByCampaignId";
import {
  AsinMetrics,
  getProductIdKey,
  useAsinPerformanceMetricsByKey
} from "ExtensionV2/queries/useAsinPerformanceMetricsByKey";
import { useProducts } from "../../queries/useProducts";

import styled from "styled-components/macro";
import { Flex } from "@rebass/grid";
import {
  DatePickerLabel as DropdownLabel,
  GlobalDatePicker,
  DEFAULT_NAMED_DATE_RANGE
} from "ExtensionV2/components/GlobalDatePicker";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import { LoadingSpinner } from "Common/components/LoadingSpinner";
import { NoticeLinkButton } from "../../../Common/components/CreateCampaignNotice";
import PerformanceByChannelChart from "../../components/charts/PerformanceByChannelChart";
import AmazonRankingCharts from "ExtensionV2/components/AmazonRankingCharts";
import {
  CampaignControlsWrapper,
  CampaignsPageWrapper
} from "../CampaignsPage/CampaignsPageRenderer";
import {
  CampaignsTableHeading,
  LinkedCampaignsSection
} from "./ProductCampaignsTable";

import { getAmazonMarketplaceInfo } from "Common/utils/amazon";
import { getStoredExcludeLagPeriod } from "Common/utils/savedTablePreferences";
import { stringForEnum } from "Common/utils/proto";
import { sendGAEvent } from "../../components/GA";
import {
  determineHasChannelMetrics,
  determineHasRanks
} from "../../components/charts/performanceByChannelUtil";
import { removeNullAndUndefined } from "Common/utils/tsUtils";

import { Site } from "ExtensionV2/queries/useSession";
import { Amazon } from "Common/proto/common/amazon_pb";
import { DropdownItemProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import { GetCampaignConfigurationsReply } from "Common/proto/edge/grpcwebPb/grpcweb_Campaigns_pb";
import { RequestedPerformanceMetrics } from "Common/proto/edge/grpcwebPb/grpcweb_PerformanceMetrics_pb";
import { Product } from "Common/proto/edge/grpcwebPb/grpcweb_Products_pb";
import {
  GoogleAdsConfiguration,
  GoogleAdsResourceStatus
} from "Common/proto/ampdPb/googleAdsConfiguration_pb";

import { backgroundError, backgroundInfo } from "../../styles/colors";
import { SETTINGS_PATH } from "ExtensionV2/ExtensionV2";
import { GlobalDateContext } from "ExtensionV2";
export const METRIC_QUERY_PARAM = "metric";
export const MARKETPLACE_QUERY_PARAM = "marketplace";
export const ASIN_QUERY_PARAM = "asin";
export const PRODUCT_ID_QUERY_PARAM = "productid";
export const DEFAULT_LAST_90_DAYS = "last-90-days";
export const PRODUCTS_PAGE_GA_CATEGORY = "Ampd: Products Page";

const SUFFIX_NO_DATA = ", No Data";
const SUFFIX_PAUSED = ", Paused";

const AGGREGATES = RequestedPerformanceMetrics.Options.AGGREGATES;
const TIMESERIES = RequestedPerformanceMetrics.Options.TIMESERIES;
const RANKS = RequestedPerformanceMetrics.Options.RANKS;

interface ProductsPageProduct extends Product.AsObject {
  campaigns: GoogleAdsConfiguration.CampaignConfiguration.AsObject[];
  hasChannelMetrics: boolean;
  allPaused: boolean;
}

const ProductCampaigns = styled.div`
  width: 100%;
  padding-left: 2em;
  padding-right: 2em;
  margin-bottom: 1em;
`;
const ProductPerformanceControls = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1em;
  padding: 1em 1em 0 1em;
`;
const NoticeBox = styled(Flex)`
  border: 2px solid gray;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 12em;
  padding: 1em;
  margin: 1em;
`;
const EllipsisDropdown = styled(Dropdown)`
  &&& {
    min-width: 40em;
    max-width: 50em;
  }
  &&& .text {
    max-width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &&& .menu {
    max-height: 30rem;
    overflow-y: auto;
  }
  &&& div > div.item > span.text {
    display: inline-block;
    white-space: break-spaces;
    overflow: visible;
    text-overflow: unset;
  }
  &&& .menu .active.item {
    font-weight: 500;
  }
`;

const ProductsPage = ({ currentSite }: { currentSite: Site }): JSX.Element => {
  const { siteAlias, siteCurrencyCode } = currentSite;
  const amazonSellerAccounts = currentSite.amazonInfo?.sellerAccounts;
  const currencyCode = siteCurrencyCode || "USD";

  const {
    data: campaignsById,
    isLoading: campaignsLoading,
    error: campaignsError
  } = useCampaignConfigurationsByCampaignId(siteAlias);

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedProductIdKey, setSelectedProductIdKey] = useState<string>("");

  const { startDate, endDate, namedRange, setGlobalNamedRange } = useContext(
    GlobalDateContext
  );

  // Get channel performance chart metrics
  const {
    data: metricsData,
    isLoading: metricsLoading,
    error: metricsError
  } = useAsinPerformanceMetricsByKey({
    siteAlias,
    startDate,
    endDate,
    requestedMetrics: [AGGREGATES, TIMESERIES]
  });

  // Get ranks chart metrics (split for async page charts loading)
  const {
    data: ranksData,
    isLoading: ranksLoading,
    error: ranksError
  } = useAsinPerformanceMetricsByKey({
    siteAlias,
    startDate,
    endDate,
    requestedMetrics: [RANKS]
  });

  // Get parent products info
  const parentOnly = [Product.RelationshipLevel.Option.PARENT];
  const {
    data: parentProducts,
    isLoading: productsLoading,
    error: productsError
  } = useProducts(siteAlias, true, parentOnly);

  // Use the default ProductsPage date range when no better one found
  const excludeAmazonLagPeriod = useMemo(() => getStoredExcludeLagPeriod(), []);
  useEffect(() => {
    const metricParam = searchParams.get(METRIC_QUERY_PARAM);
    const marketplaceParam = searchParams.get(MARKETPLACE_QUERY_PARAM);
    const asinParam = searchParams.get(ASIN_QUERY_PARAM);
    const productIdParam = searchParams.get(PRODUCT_ID_QUERY_PARAM);

    // We want to respect the values set for real deeplinks & load them into
    // the UI as requested, but internal sidebar links require we load default
    // page state - including overriding the campaign page 30day date range to
    // a 90day date range. This helps identify an internal link.
    const notRealDeeplink =
      namedRange === DEFAULT_NAMED_DATE_RANGE &&
      !metricParam &&
      !marketplaceParam &&
      !(asinParam || productIdParam);

    if (notRealDeeplink) {
      setGlobalNamedRange(DEFAULT_LAST_90_DAYS);
    }
  }, [excludeAmazonLagPeriod, namedRange, searchParams, setGlobalNamedRange]);

  // Collect the site's ads metrics data - mapped by Marketplace/ProductId
  const performanceMetricsByProductIdKey: Immutable.Map<
    string,
    AsinMetrics
  > = useMemo(() => {
    return !metricsData ? Immutable.Map() : Immutable.Map(metricsData);
  }, [metricsData]);

  // Collect the site's ranks data - mapped by Marketplace/ProductId
  const ranksByProductIdKey: Immutable.Map<
    string,
    AsinMetrics
  > = useMemo(() => {
    return !ranksData ? Immutable.Map() : Immutable.Map(ranksData);
  }, [ranksData]);

  // productByProductIdKey maps parent product information by its productId-key
  // (marketplace+productId, since productId is only unique per marketplace) for
  // products with campaigns. Used for product-related data access when selected
  // in the UI. Any related campaign information is also added onto the parent
  // product information. This allows product info & its associated campaigns
  // to be accessed by productIdKey.
  /*
    productByProductIdKey
    key: <marketplace>_<productId> (eg: "2_B111111111")
    value: { productId, marketplace, campaigns: [{}], ... }
  */
  const productByProductIdKey: {
    [productIdKey: string]: ProductsPageProduct;
  } = useMemo(() => {
    if (
      productsLoading ||
      productsError ||
      campaignsLoading ||
      campaignsError
    ) {
      return {}; // wait
    }

    if (!performanceMetricsByProductIdKey.size || !parentProducts?.length) {
      return {}; // missing important data for products logic
    }

    const siteCampaigns: GetCampaignConfigurationsReply.CampaignConfiguration.AsObject[] = Array.from(
      campaignsById?.values() ?? []
    );
    return getProductByProductIdKeyMappings(
      parentProducts,
      performanceMetricsByProductIdKey,
      siteCampaigns
    );
  }, [
    parentProducts,
    productsLoading,
    productsError,
    campaignsById,
    campaignsLoading,
    campaignsError,
    performanceMetricsByProductIdKey
  ]);

  // Updates the selected product productIdKey (marketplace/productId) & URL params
  const updateSelectedProduct = useCallback(
    (productIdKey: string) => {
      setSelectedProductIdKey(productIdKey);

      // Update the URL per selection
      const product = productByProductIdKey[productIdKey];
      if (product) {
        const marketplace = stringForEnum(
          Amazon.Marketplace.Option,
          product.seller?.amazon?.marketplace
        );

        if (
          searchParams.get(MARKETPLACE_QUERY_PARAM) !== marketplace ||
          searchParams.get(PRODUCT_ID_QUERY_PARAM) !== product.productId
        ) {
          searchParams.set(MARKETPLACE_QUERY_PARAM, marketplace || "");
          searchParams.set(PRODUCT_ID_QUERY_PARAM, product.productId);
          setSearchParams(searchParams, { replace: true });
        }
      }
    },
    [productByProductIdKey, searchParams, setSearchParams]
  );

  // Options for the Product dropdown selection
  const dropdownOptions = useMemo(() => {
    if (_.isEmpty(productByProductIdKey)) {
      return [];
    }

    const options: DropdownItemProps[] = Object.values(productByProductIdKey)
      .map((product: ProductsPageProduct, index: number) => {
        const marketplaceInfo = getAmazonMarketplaceInfo(
          product.seller?.amazon?.marketplace
        );

        const productIdKey = getProductIdKey(
          product.seller?.amazon?.marketplace ?? null,
          product.productId
        );

        if (!productIdKey) {
          return null;
        }

        const ranksMetrics = ranksByProductIdKey.get(productIdKey);
        const noData =
          !product.hasChannelMetrics &&
          (!ranksMetrics || !determineHasRanks(ranksMetrics));

        let descSuffix = "";
        if (noData) {
          descSuffix = SUFFIX_NO_DATA;
        } else if (product.allPaused) {
          descSuffix = SUFFIX_PAUSED;
        }
        const desc = `${marketplaceInfo?.domain ?? ""}${descSuffix}`;

        return {
          key: index,
          value: productIdKey,
          text: `[${product.productId}] ${product.title || ""}`,
          description: desc,
          disabled: noData
        };
      })
      .filter(removeNullAndUndefined);

    return options.sort(productsOptionsDropdownSortFn);
  }, [productByProductIdKey, ranksByProductIdKey]);

  // Builds the dropdown of products.
  // Sort products initially, then when ranks are ready, sort a final time
  // because we will now know the hasData cases.
  // Sets the default selection per:
  //  1) url search params
  //  2) user selection
  //  3) first US ProductId
  //  4) first ProductId
  useEffect(() => {
    if (
      selectedProductIdKey === "" &&
      !_.isEmpty(productByProductIdKey) &&
      !_.isEmpty(dropdownOptions)
    ) {
      const productId =
        searchParams.get(PRODUCT_ID_QUERY_PARAM) ||
        // TODO: Temp, remove back-compatibility support after reasonable delay.
        searchParams.get(ASIN_QUERY_PARAM) ||
        "";
      const marketplaceName =
        searchParams.get(MARKETPLACE_QUERY_PARAM) || "UNKNOWN";
      const marketplace =
        Amazon.Marketplace.Option[
          marketplaceName as keyof typeof Amazon.Marketplace.Option
        ];

      const queryParamProductIdKey = getParentProductIdKey(
        productByProductIdKey,
        marketplace,
        productId
      );

      const sortedFirstProductIdKey = dropdownOptions?.[0]?.value || "";
      const anyKey = Object.keys(productByProductIdKey)?.[0] || "";

      const selected =
        queryParamProductIdKey || String(sortedFirstProductIdKey) || anyKey;
      updateSelectedProduct(selected);
    }
  }, [
    selectedProductIdKey,
    productByProductIdKey,
    searchParams,
    updateSelectedProduct,
    ranksByProductIdKey,
    dropdownOptions
  ]);

  const currentProduct = productByProductIdKey[selectedProductIdKey];
  const currentMetrics =
    performanceMetricsByProductIdKey.get(selectedProductIdKey) || {};
  const currentRanks = ranksByProductIdKey.get(selectedProductIdKey) || {};

  // Boolean state checking for conditionals
  const loading = campaignsLoading || productsLoading || metricsLoading;
  const hasSellerConnection = !!amazonSellerAccounts?.length;
  const hasProducts = !_.isEmpty(productByProductIdKey);
  const hasMetrics = !!performanceMetricsByProductIdKey?.size;
  const noErrors = !metricsError && !productsError && !campaignsError;
  const noIssues = noErrors && hasProducts && hasSellerConnection;

  const connectSellerCentralLink = (
    <NoticeBox>
      <NoticeLinkButton
        toPath={`../${SETTINGS_PATH}`}
        buttonText="Go to Settings"
      >
        <h3>Your Amazon Seller account is not yet connected to Ampd.</h3>
        <p style={{ width: "70%", textAlign: "center" }}>
          For Amazon product performance insights about your Ampd campaign
          ASINs, go to your Settings and connect Ampd to your Amazon Seller
          Central account.
        </p>
      </NoticeLinkButton>
    </NoticeBox>
  );

  const productsPageContent =
    productByProductIdKey == null ? (
      // prevent contentful or the null states from flashing when first mounted
      <></>
    ) : !hasProducts ? (
      <NoticeBox>
        <NoticeLinkButton
          toPath={`/t/${siteAlias}/product-ads/create`}
          buttonText="Create a new campaign"
        >
          <h3>It looks like we weren't able to find any of your products.</h3>
          <p>
            To begin tracking the Ampd performance of an Amazon product, create
            a campaign for its ASIN.
          </p>
        </NoticeLinkButton>
      </NoticeBox>
    ) : (
      <AmazonRankingCharts
        siteAlias={siteAlias}
        product={currentProduct}
        dateRangeStartDate={startDate}
        dateRangeEndDate={endDate}
        ranks={currentRanks}
        ranksLoading={ranksLoading}
        ranksError={ranksError}
      />
    );

  sendGAEvent(PRODUCTS_PAGE_GA_CATEGORY, "Loaded", siteAlias);
  return (
    <CampaignsPageWrapper style={{ minWidth: "55em" }}>
      <CampaignControlsWrapper>
        <ProductPerformanceControls>
          <div style={{ width: "100%" }}>
            <DropdownLabel>Amazon Product</DropdownLabel>
            <EllipsisDropdown
              disabled={loading || !noErrors || !hasProducts}
              selection
              search
              loading={loading}
              options={dropdownOptions}
              onChange={(
                _e: SyntheticEvent<HTMLElement>,
                data: DropdownProps
              ) => updateSelectedProduct(String(data.value))}
              value={selectedProductIdKey}
            />
          </div>
          <GlobalDatePicker />
        </ProductPerformanceControls>
      </CampaignControlsWrapper>

      {/* Chart of Product Performance By Channel */}
      <div style={{ padding: "1em" }}>
        {/* Found no Seller connection. */}
        {!loading && !hasSellerConnection && connectSellerCentralLink}

        {/* Have products & Seller connection, but error getting metrics. */}
        {hasProducts && hasSellerConnection && !!metricsError && (
          <NoticeBox bg={backgroundError}>
            <p>
              Sorry, there was an error loading data for the Product Performance
              by Channel chart. Please reload the page to try again. If the
              issue persists, please contact Ampd.
            </p>
          </NoticeBox>
        )}

        {/* Done loading, no error, but found no metrics. */}
        {!loading && noIssues && !hasMetrics && (
          <NoticeBox bg={backgroundInfo} style={{ minHeight: "5em" }}>
            <p>
              No product performance metrics found. If you just connected your
              Amazon Seller Central account, it may take up to 24 hours for
              metrics to be available.
            </p>
          </NoticeBox>
        )}

        {/* Metrics, but not for this specific channel. */}
        {!loading &&
          noIssues &&
          hasMetrics &&
          !currentProduct?.hasChannelMetrics && (
            <NoticeBox bg={backgroundInfo} style={{ minHeight: "5em" }}>
              <p>No channel performance metrics found for this product.</p>
            </NoticeBox>
          )}

        {/* Loading or found channel metrics */}
        {noIssues && (loading || currentProduct?.hasChannelMetrics) && (
          <PerformanceByChannelChart
            metricsDataLoading={metricsLoading}
            productMetrics={currentMetrics}
            product={currentProduct}
            currencyCode={currencyCode}
          />
        )}
      </div>

      {/* Table of campaigns for the selected product */}
      {currentProduct != null && (
        <ProductCampaigns>
          <CampaignsTableHeading currentProduct={currentProduct} />
          <LinkedCampaignsSection
            currentProduct={currentProduct}
            error={campaignsError}
          />
        </ProductCampaigns>
      )}

      {/* Ranks charts */}
      <div>
        {loading && (
          <LoadingSpinner>
            Loading Daily Amazon Product Search Metrics
          </LoadingSpinner>
        )}

        {!loading && !noErrors && (
          <NoticeBox bg={backgroundInfo} style={{ minHeight: "5em" }}>
            <p style={{ textAlign: "center" }}>
              There was an error when loading the chart.
            </p>
          </NoticeBox>
        )}

        {!loading && noErrors && productsPageContent}
      </div>
    </CampaignsPageWrapper>
  );
};

// Sorts by: Has Data > Has Enabled Campaigns > Marketplace > ProductId
const productsOptionsDropdownSortFn = (
  optionA: DropdownItemProps,
  optionB: DropdownItemProps
) => {
  const aNoData = String(optionA.description).includes(SUFFIX_NO_DATA);
  const bNoData = String(optionB.description).includes(SUFFIX_NO_DATA);

  const aIsPaused =
    !aNoData && String(optionA.description).includes(SUFFIX_PAUSED);
  const bIsPaused =
    !bNoData && String(optionB.description).includes(SUFFIX_PAUSED);

  return (
    +aNoData - +bNoData || // noData grouped last
    +aIsPaused - +bIsPaused || // paused status grouped last within each data-state grouping
    String(optionA.value).localeCompare(String(optionB.value)) // ASC by Marketplace > ProductID (within status grouping)
  );
};

// Given a productId - child or parent - return the parent productId (maybe itself).
const getParentProductIdKey = (
  productByProductIdKey: { [productIdKey: string]: ProductsPageProduct },
  marketplace: Amazon.Marketplace.Option,
  productId: string
) => {
  if (_.isEmpty(productByProductIdKey) || !marketplace || !productId) {
    return null;
  }

  const productIdKey = getProductIdKey(marketplace, productId);
  if (!productIdKey) {
    return null;
  }

  if (Object.hasOwn(productByProductIdKey, productIdKey)) {
    return productIdKey;
  }

  const foundParentProduct = Object.values(productByProductIdKey).find(
    parentProduct => {
      // Look through all of this product's related productIds to find a match
      const productIds = Immutable.Set(
        [
          parentProduct.productId,
          parentProduct.parentProductId,
          ...parentProduct.variantProductIdsList
        ].filter(Boolean)
      );

      return productIds.includes(productId);
    }
  );

  const parentProductId = foundParentProduct?.productId ?? productId;

  return getProductIdKey(marketplace, parentProductId);
};

// Returns a map of each product by its "product id key" (a string key combo
// of marketplace + productId (eg: "2_B111111111")). The product's related
// campaign information is also included within the product for use in the UI.
const getProductByProductIdKeyMappings = (
  parentProducts: Array<Product.AsObject>,
  performanceMetricsByProductIdKey: Immutable.Map<string, AsinMetrics>,
  siteCampaigns: GetCampaignConfigurationsReply.CampaignConfiguration.AsObject[]
): { [productIdKey: string]: ProductsPageProduct } => {
  // Gather products for access, expanding with related info for the page.
  return parentProducts.reduce((byProductIdKey, product) => {
    const productIdKey = getProductIdKey(
      product.seller?.amazon?.marketplace ?? null,
      product.productId
    );

    if (!productIdKey) {
      return byProductIdKey; // skip if no key
    }

    // All of this product's ProductIds
    const productIds = Immutable.Set(
      [
        product.productId,
        product.parentProductId,
        ...product.variantProductIdsList
      ].filter(Boolean)
    );

    const relatedCampaigns = siteCampaigns
      .filter(c =>
        productIds.includes(c.ampdProductDetails?.amazon?.asin ?? "")
      )
      .map(c => c?.ampdResourceConfiguration?.googleAds?.campaignConfiguration)
      .filter(removeNullAndUndefined);

    // Check product-level metrics data
    const metrics = performanceMetricsByProductIdKey.get(productIdKey);
    const hasChannelMetrics = metrics && determineHasChannelMetrics(metrics);
    const allPaused = relatedCampaigns.every(
      c => stringForEnum(GoogleAdsResourceStatus.Option, c.status) === "PAUSED"
    );

    byProductIdKey[productIdKey] = {
      ...product,
      campaigns: relatedCampaigns,
      hasChannelMetrics: !!hasChannelMetrics,
      allPaused: allPaused
    };

    return byProductIdKey;
  }, {} as { [productIdKey: string]: ProductsPageProduct });
};

export default ProductsPage;
