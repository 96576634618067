import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import Immutable from "immutable";
import { useSearchParams } from "react-router-dom";
import { useSession } from "ExtensionV2/queries/useSession";

import * as Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import styled from "styled-components/macro";
import { Button, Dropdown } from "semantic-ui-react";
import { DatePickerLabel as DropdownLabel } from "ExtensionV2/components/GlobalDatePicker";
import { LoadingSpinner } from "Common/components/LoadingSpinner";
import { ChannelChartTiles } from "./SeriesTile";

import { titleCase } from "change-case";
import { backgroundDark } from "ExtensionV2/styles/colors";
import { ratioSmooth } from "ExtensionV2/components/charts/util";
import {
  determineIsRevenueMisaligned,
  performanceByChannelColor,
  supportedChartMetricTypes
} from "./performanceByChannelUtil";
import {
  buildCampaignStartVerticals,
  buildPerformanceByChannelChartConfig,
  makeSeries
} from "./highchartsUtil";
import {
  getOrCalcMetricAggregate,
  getSmoothingTimeseriesLists,
  getOrCalcMetricTimeseries
} from "./metricsUtils";

import {
  AMAZON_ADS_CHANNEL,
  GOOGLE_ADS_CHANNEL,
  ORGANIC_CHANNEL,
  SELLER_CHANNEL
} from "./metricsConfigs";

import { METRIC_QUERY_PARAM } from "../../pages/ProductsPage/ProductsPage";
import {
  useWantsProductsPageMisalignedDataEnabled,
  useWantsProOperatorFeatures
} from "../../../Common/utils/featureFlags";

const MAX_TACOS_VALUE = 3; // aka: 300%
const MAX_TACOS_AGG_FACTOR = 2; // aka: 2x TACOS date range aggregate
const SMOOTHED_DAYS = 14;

const BasicDropdown = styled(Dropdown)`
  &&& .text {
    font-weight: normal;
  }
  &&& .menu {
    max-height: 30rem;
    overflow-y: auto;
  }
  &&& .menu .active.item {
    font-weight: normal;
  }
`;
const ChartBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 45em;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 2px solid ${backgroundDark};
`;
const ChannelsPerformanceChart = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 0 1em 1.5em;

  & > div > h1 {
    text-align: center;
    font-size: 3em;
    min-height: fit-content;
  }
`;
const Subtitle = styled.p`
  max-width: 80%;
  margin: auto;
  text-align: center;
  font-size: smaller;
  padding: 1em;
  span.shortened {
    /* Variants list - single line with ellipsis truncation */
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  span.datawarning {
    color: red;
    font-weight: bold;
  }
`;

// Gathers the configured supported metrics into a list of dropdown options.
const defaultMetricsDropdownOptions = Object.entries(
  supportedChartMetricTypes
).map(([metricType, metricTypeInfo]) => ({
  key: metricTypeInfo.name,
  value: metricType, // The enum (eg: "IMPRESSIONS")
  text: metricTypeInfo.name // The humanreadable name (eg: "Impressions")
}));

// Displays a chart for an Amazon product's metrics broken down by its metrics
// channels (eg: AmazonAds, GoogleAds, etc) with channel summary tiles
// displayed across the top with their series-aggregate values and the series
// lines below. Displayed product & metrics are based on the selection & aligns
// with the URL search params.
function PerformanceByChannelChart({
  metricsDataLoading,
  currencyCode,
  productMetrics,
  product
}) {
  const {
    currentSite: { siteAlias }
  } = useSession();
  const wantsProOperatorFeatures = useWantsProOperatorFeatures();
  const wantsProductsPageMisalignedDataEnabled = useWantsProductsPageMisalignedDataEnabled();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedMetric, setSelectedMetric] = useState(null); // Metrics for selected product
  const [isChannelSeriesHiddenMap, setIsChannelSeriesHiddenMap] = useState(
    Immutable.Map()
  );
  const [showSmoothed, setShowSmoothed] = useState(true);
  const [showDataWarning, setShowDataWarning] = useState(false);

  const updateSelectedMetric = useCallback(
    metric => {
      const upperMetric = metric?.toUpperCase();
      setSelectedMetric(upperMetric);

      // Update the URL per selection
      searchParams.set(METRIC_QUERY_PARAM, upperMetric);
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams]
  );

  // TODO(clint): Rollout feature; remove after Seller warehousing.
  const defaultSkips = wantsProOperatorFeatures
    ? {}
    : {
        TACOS: [GOOGLE_ADS_CHANNEL, AMAZON_ADS_CHANNEL],
        REVENUE: []
      };
  const [skipMetricsChannels, setSkipMetricsChannels] = useState(defaultSkips);
  const [metricsDropdownOptions, setMetricsDropdownOptions] = useState(
    defaultMetricsDropdownOptions
  );
  useEffect(() => {
    const isRevenueMisaligned = determineIsRevenueMisaligned(
      productMetrics.dateRangeAggregates
    );

    // We generally do not want to show data that is incomplete/misaligned because
    // it's misleading & could result in mis-informed decisions by users (or just
    // be confusing & require customer support team explanations). But we can make
    // site-level overrides exceptions on a case-by-case basis.
    if (wantsProductsPageMisalignedDataEnabled) {
      setShowDataWarning(isRevenueMisaligned);
      return;
    }

    if (isRevenueMisaligned) {
      // Filter the dropdown options
      const skipChannels = ["TACOS"];
      const filteredMetricsDropdownOptions = defaultMetricsDropdownOptions.filter(
        option => !skipChannels.includes(option.value)
      );
      setMetricsDropdownOptions(filteredMetricsDropdownOptions);

      // Filter out specific channels when revenue misaligned
      setSkipMetricsChannels(prev => {
        return {
          ...prev,
          // Add/override to previous values
          TACOS: [SELLER_CHANNEL, GOOGLE_ADS_CHANNEL, AMAZON_ADS_CHANNEL],
          REVENUE: [SELLER_CHANNEL, ORGANIC_CHANNEL]
        };
      });
    }
  }, [productMetrics, wantsProductsPageMisalignedDataEnabled]);

  // Set dropdown per page, selection, or defaults when needed.
  useEffect(() => {
    // Set the selected metric per URL or default to TACOS.
    if (selectedMetric === null) {
      const defaultMetric = supportedChartMetricTypes.TACOS.name.toUpperCase();
      const metricParam = searchParams.get(METRIC_QUERY_PARAM)?.toUpperCase();

      updateSelectedMetric(
        supportedChartMetricTypes[metricParam] ? metricParam : defaultMetric
      );
    }
  }, [selectedMetric, searchParams, updateSelectedMetric]);

  const channelTimeseries = useMemo(() => {
    if (_.isEmpty(productMetrics) || !selectedMetric) {
      return Immutable.List(); // nothing available yet
    }

    const allProductTimeseries = productMetrics.timeseries;
    if (_.isEmpty(allProductTimeseries)) {
      return Immutable.List();
    }

    const dates = allProductTimeseries.unixDatesList;
    if (_.isEmpty(dates)) {
      return Immutable.List(); // no dates, no timeseries
    }

    const selectedMetricType = supportedChartMetricTypes[selectedMetric];
    const channelTimeseries = selectedMetricType.channels
      .map(channel => {
        const { channelType, metricField, metricParts } = channel;
        if (!channelType || !metricField) {
          return null;
        }

        if (skipMetricsChannels[selectedMetric]?.includes(channelType.key)) {
          return null;
        }

        // Set the performance by channel tile's color
        channelType.color = performanceByChannelColor[channelType.key] ?? "";

        const timeseries = productMetrics.timeseries[channelType.sourceField];
        const aggregates =
          productMetrics.dateRangeAggregates[channelType.sourceField];
        if (!timeseries) {
          return null;
        }

        const metricTimeseries = buildMetricTimeseriesForDisplay(
          channel,
          aggregates,
          timeseries, // this specific timeseries
          allProductTimeseries, // used to recalc
          selectedMetric, // key
          metricParts, // custom type
          showSmoothed // boolean
        );

        const seriesName = titleCase(metricField);
        const isHidden = isChannelSeriesHiddenMap.get(channelType.key);

        return makeSeries(
          seriesName,
          dates,
          metricTimeseries,
          channelType.color,
          true, // always show markers
          isHidden
        );
      })
      .filter(Boolean)
      .sort();

    return Immutable.List(channelTimeseries);
  }, [
    selectedMetric,
    productMetrics,
    isChannelSeriesHiddenMap,
    showSmoothed,
    skipMetricsChannels
  ]);

  // This is rebuilt custom for each Metric based on its metric config
  const chartConfig = useMemo(() => {
    let campaignVerticals = null;
    if (product) {
      const campaignStartlabelOffsetY = -400;
      campaignVerticals = buildCampaignStartVerticals(
        product.campaigns,
        siteAlias,
        searchParams,
        campaignStartlabelOffsetY
      );
    }

    return buildPerformanceByChannelChartConfig(
      selectedMetric,
      channelTimeseries,
      currencyCode,
      campaignVerticals,
      skipMetricsChannels[selectedMetric]
    );
  }, [
    channelTimeseries,
    product,
    currencyCode,
    searchParams,
    selectedMetric,
    siteAlias,
    skipMetricsChannels
  ]);

  // Retrieves the aggregates from selected Product's applicable channel metrics
  // per the selected metrics type.
  const selectedAggregatesList = useMemo(() => {
    if (_.isEmpty(productMetrics) || !selectedMetric) {
      return Immutable.List(); // empty for now
    }

    const selectedMetricType = supportedChartMetricTypes[selectedMetric];
    const channelAggregates = selectedMetricType.channels
      .map(channel => {
        const { channelType } = channel;
        if (!channel) {
          return null;
        }

        // Set the performance by channel tile's color
        channelType.color = performanceByChannelColor[channelType.key] ?? "";

        const aggregates =
          productMetrics.dateRangeAggregates[channelType.sourceField];
        if (!aggregates) {
          return null;
        }

        const metricAggValue = getOrCalcMetricAggregate(
          channel,
          aggregates,
          channelTimeseries,
          productMetrics.timeseries
        );

        const metricDef = supportedChartMetricTypes[selectedMetric]?.metricDef;
        return {
          channelType: channelType,
          aggMetricValue: metricAggValue,
          // All metricDefs are called with currency, but not all use it.
          metricDef: metricDef(aggregates.currencyCode)
        };
      })
      .filter(Boolean)
      .sort();

    return Immutable.List(channelAggregates);
  }, [selectedMetric, productMetrics, channelTimeseries]);

  const handleDropdownMetricSelect = (e, { value }) => {
    updateSelectedMetric(value);
  };

  const handleTileClick = channelType => {
    const channel = channelType.key;

    setIsChannelSeriesHiddenMap(
      isChannelSeriesHiddenMap.set(
        channel,
        !isChannelSeriesHiddenMap.get(channel)
      )
    );
  };

  const ToggleSmoothingButton = (
    <Button.Group size="tiny">
      <Button onClick={() => setShowSmoothed(s => !s)} primary={!showSmoothed}>
        1 Day
      </Button>
      <Button.Or />
      <Button onClick={() => setShowSmoothed(s => !s)} primary={showSmoothed}>
        {`${SMOOTHED_DAYS} Day Avg`}
      </Button>
    </Button.Group>
  );

  const DataWarning = (
    <span className="datawarning">
      Data in the below chart does not meet Ampd's requirements for display. The
      data comes from multiple sources and appears misaligned because the
      channel revenues exceed total revenues. This can occur when when a large
      cross-product halo effect is present or when some variant ASIN's are not
      being reported properly.
    </span>
  );

  return (
    <ChannelsPerformanceChart>
      <div>
        <h1 style={{ paddingBottom: 0, marginBottom: 0 }}>
          Product Performance By Channel
        </h1>
        <Subtitle>
          {product?.title ?? ""} <br />
          <span className="shortened">
            {product?.variantProductIdsList?.length > 0 &&
              `${product?.variantProductIdsList.join(" | ")}`}
          </span>
          {showDataWarning ? DataWarning : null}
        </Subtitle>
      </div>
      <div style={{ paddingBottom: "1em", paddingTop: 0 }}>
        <DropdownLabel>Metric</DropdownLabel>
        <BasicDropdown
          options={metricsDropdownOptions}
          onChange={handleDropdownMetricSelect}
          value={selectedMetric?.toUpperCase()}
          style={{ marginRight: "1em" }}
          selection
        />
        {ToggleSmoothingButton}
      </div>
      <div>
        <ChannelChartTiles
          channels={selectedAggregatesList}
          selectedMetric={selectedMetric}
          isChannelSeriesHiddenMap={isChannelSeriesHiddenMap}
          handleTileClick={handleTileClick}
          skipChannels={skipMetricsChannels[selectedMetric]}
        />
      </div>

      {
        <ChartBox>
          {metricsDataLoading ? (
            <LoadingSpinner>Loading Product Metrics By Channel</LoadingSpinner>
          ) : (
            <HighchartsReact
              highcharts={Highcharts}
              constructorType="stockChart"
              options={chartConfig}
            />
          )}
        </ChartBox>
      }
    </ChannelsPerformanceChart>
  );
}
export default PerformanceByChannelChart;

export const buildMetricTimeseriesForDisplay = (
  channel,
  channelAggregates,
  channelTimeseries, // this specific timeseries
  allTimeseries, // used to recalc
  selectedMetric, // key
  metricParts, // custom type
  showSmoothed // boolean
) => {
  let metricTimeseries;
  if (showSmoothed && metricParts?.numerators?.length) {
    const notNegative = 0;
    let decimalPlaces = metricParts?.denominators?.length
      ? 3 // smoothed ratios keep decimals
      : 0; // smoothed non-ratios do not keep decimals

    // Keep a reasonable "max" value
    let maxValue = null;
    if (selectedMetric === "TACOS") {
      const aggValue =
        getOrCalcMetricAggregate(
          channel,
          channelAggregates,
          channelTimeseries,
          allTimeseries
        ) || 0;

      // Allow TACOS to be 2x the agg value
      maxValue = Math.max(aggValue * MAX_TACOS_AGG_FACTOR, MAX_TACOS_VALUE);
    }

    // Retrieve the configured timeseries numerators & denominators
    const { numerators, denominators } = getSmoothingTimeseriesLists(
      channel,
      allTimeseries,
      channelTimeseries
    );

    metricTimeseries = ratioSmooth(
      numerators,
      denominators,
      SMOOTHED_DAYS,
      notNegative,
      maxValue,
      decimalPlaces
    );
  } else {
    metricTimeseries = getOrCalcMetricTimeseries(
      channel,
      channelTimeseries,
      allTimeseries
    );
  }

  return metricTimeseries;
};
