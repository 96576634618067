import React, { useMemo } from "react";

import {
  MetricsSort,
  MetricsTable,
  TMetricsTable
} from "ExtensionV2/components/metricsTable/MetricsTable";
import { Dropdown, Icon, Segment, Table } from "semantic-ui-react";
import {
  Column,
  COL_ATC,
  COL_ATC_RATE,
  COL_CONVERSIONS,
  COL_CONVERSION_RATE,
  COL_CONVERSION_VALUE,
  COL_COST_PER_ADD_TO_CART,
  COL_COST_PER_DETAIL_PAGE_VIEW,
  COL_COST_PER_MILLE,
  COL_DETAIL_PAGE_VIEW_RATE,
  COL_DETAIL_PAGE_VIEWS,
  COL_FACEBOOK_COST_PER_OUTBOUND_CLICK,
  COL_FACEBOOK_INTERACTIONS,
  COL_FACEBOOK_OUTBOUND_CLICK_RATE,
  COL_FACEBOOK_OUTBOUND_CLICKS,
  COL_IMPRESSIONS,
  COL_MARKETPLACE_CLICK_RATE,
  COL_MARKETPLACE_CLICKS,
  COL_MARKETPLACE_COST_PER_CLICK,
  COL_NTB_CONVERSION_RATE,
  COL_NTB_CONVERSIONS,
  COL_NTB_REVENUE,
  COL_NTB_REVENUE_RATE,
  COL_NTB_ROAS,
  COL_NTB_UNITS_SOLD,
  COL_NTB_UNITS_SOLD_RATE,
  COL_ROAS,
  COL_SPEND,
  COL_UNITS_SOLD,
  COL_ACCOUNT_ID,
  COL_AD_ID,
  COL_AD_SET_ID,
  COL_CAMPAIGN_ID,
  COL_NAME
} from "../../components/metricsTable/columns";
import { Retailer } from "Common/proto/common/retailer_pb";
import styled from "styled-components";

import { fetchRows, testRows } from "./fetchRows";

export type FacebookTableData = {
  key: string;
  // metrics
  addToCartRate: number;
  addToCartClicks: number;
  brandReferralBonus: number;
  conversionRate: number;
  conversions: number;
  conversionValue: number;
  costPerAddToCart: number;
  costPerDetailPageView: number;
  costPerMille: number;
  detailPageViewRate: number;
  detailPageViews: number;
  facebookCostPerOutboundClick: number;
  facebookInteractions: number;
  facebookOutboundClickRate: number;
  facebookOutboundClicks: number;
  impressions: number;
  marketplaceClickRate: number;
  marketplaceClicks: number;
  marketplaceCostPerClick: number;
  ntbConversionRate: number;
  ntbConversions: number;
  ntbRevenue: number;
  ntbRevenueRate: number;
  ntbROAS: number;
  ntbUnitsSold: number;
  ntbUnitsSoldRate: number;
  roas: number;
  spend: number;
  unitsSold: number;
  // meta data
  accountID: string;
  adID: string;
  adSetID: string;
  campaignID: string;
  name: string;
  retailer: Retailer.Option;

  prev?: Omit<FacebookTableData, "key">;
};

const StyledFacebookTableRow = styled.tr<{
  isExpanded: boolean;
  depth: number;
}>`
  > div {
    display: flex;
    flex-direction: row;
  }

  td.name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    p {
      margin: 0 0 0 ${({ depth }) => depth}rem;
    }

    i {
      display: ${({ isExpanded }) => (isExpanded ? "block" : "none")};
      cursor: pointer;
    }

    :hover {
      i {
        display: block;
      }
    }
  }
`;

const FacebookTableRow = ({
  columns,
  data,
  isExpanded,
  depth,
  toggleExpandedRow
}: {
  data: FacebookTableData;
  columns: Array<Column<FacebookTableData>>;
  isExpanded: boolean;
  depth: number;
  toggleExpandedRow?: (key: string) => void | undefined;
}) => {
  const columnCells = columns.map(c => {
    switch (c.dataName) {
      case COL_NAME.dataName:
        return (
          <td className={c.dataName} key={String(c.dataName)}>
            <p>{data.name}</p>
            {toggleExpandedRow && (
              <Icon
                aria-label="Expand row"
                name="bars"
                color="blue"
                onClick={() => toggleExpandedRow(data.key)}
              />
            )}
          </td>
        );
      default:
        return (
          <td className={c.dataName} key={String(c.dataName)}>
            {c.format(data, false)}
          </td>
        );
    }
  });

  return (
    <StyledFacebookTableRow isExpanded={isExpanded} depth={depth}>
      <td>
        <input type="checkbox" />
      </td>
      {columnCells}
    </StyledFacebookTableRow>
  );
};

export function FacebookPageV2(): JSX.Element {
  const [viewLevel, setViewLevel] = React.useState<"campaign" | "adSet" | "ad">(
    "campaign"
  );

  const handleUpdateSort = (_sort: MetricsSort<FacebookTableData>): void => {
    return;
  };

  const tableConfig = useMemo(() => {
    const mapDataRowsToTableRows = (
      rows: Array<FacebookTableData>,
      columns: Array<Column<FacebookTableData>>,
      expandedRows: Set<string>,
      toggleExpandedRow: (key: string) => void
    ): Array<JSX.Element> => {
      const campaignToAdSets = new Map<
        string,
        { row: FacebookTableData; children: Array<FacebookTableData> }
      >();
      const adSetToAds = new Map<
        string,
        { row: FacebookTableData; children: Array<FacebookTableData> }
      >();
      const ads = new Map<
        string,
        { row: FacebookTableData; children: Array<FacebookTableData> }
      >();

      rows.forEach(row => {
        if (row.campaignID && !row.adSetID && !row.adID) {
          campaignToAdSets.set(row.campaignID, {
            row,
            children: []
          });
        }
      });

      rows.forEach(row => {
        if (row.adSetID && !row.adID) {
          adSetToAds.set(row.adSetID, {
            row,
            children: []
          });

          const parentRow = campaignToAdSets.get(row.campaignID);
          if (parentRow) {
            parentRow.children.push(row);
          }
        }
      });

      rows.forEach(row => {
        if (row.adID) {
          ads.set(row.adID, {
            row,
            children: []
          });
          const parentRow = adSetToAds.get(row.adSetID);
          if (parentRow) {
            parentRow.children.push(row);
          }
        }
      });

      let topLevel = campaignToAdSets;

      switch (viewLevel) {
        case "campaign":
          topLevel = campaignToAdSets;
          break;
        case "adSet":
          topLevel = adSetToAds;
          break;
        case "ad":
          topLevel = ads;
          break;
      }

      return [...topLevel.values()].map(
        ({ row: topLevelRow, children: secondLevelRows }) => {
          return (
            <Table.Body key={topLevelRow.key}>
              <FacebookTableRow
                depth={0}
                toggleExpandedRow={
                  secondLevelRows.length ? toggleExpandedRow : undefined
                }
                data={topLevelRow}
                columns={columns}
                isExpanded={expandedRows.has(topLevelRow.key)}
              />
              {expandedRows.has(topLevelRow.key) &&
                secondLevelRows.map(secondLevelRow => {
                  return (
                    <>
                      <FacebookTableRow
                        depth={1}
                        key={secondLevelRow.key}
                        toggleExpandedRow={
                          secondLevelRows.length ? toggleExpandedRow : undefined
                        }
                        data={secondLevelRow}
                        columns={columns}
                        isExpanded={expandedRows.has(secondLevelRow.key)}
                      />
                      {expandedRows.has(secondLevelRow.key) &&
                        adSetToAds
                          .get(secondLevelRow.adSetID)
                          ?.children.map(thirdLevelRow => {
                            return (
                              <FacebookTableRow
                                depth={2}
                                key={thirdLevelRow.key}
                                data={thirdLevelRow}
                                columns={columns}
                                isExpanded={false}
                              />
                            );
                          })}
                    </>
                  );
                })}
            </Table.Body>
          );
        }
      );
    };

    const config: TMetricsTable<FacebookTableData> = {
      columns: [
        COL_NAME,

        COL_ATC,
        COL_ATC_RATE,
        COL_CONVERSIONS,
        COL_CONVERSION_RATE,
        COL_CONVERSION_VALUE,
        COL_COST_PER_ADD_TO_CART,
        COL_COST_PER_DETAIL_PAGE_VIEW,
        COL_COST_PER_MILLE,
        COL_DETAIL_PAGE_VIEW_RATE,
        COL_DETAIL_PAGE_VIEWS,
        COL_FACEBOOK_COST_PER_OUTBOUND_CLICK,
        COL_FACEBOOK_INTERACTIONS,
        COL_FACEBOOK_OUTBOUND_CLICK_RATE,
        COL_FACEBOOK_OUTBOUND_CLICKS,
        COL_IMPRESSIONS,
        COL_MARKETPLACE_CLICK_RATE,
        COL_MARKETPLACE_CLICKS,
        COL_MARKETPLACE_COST_PER_CLICK,
        COL_NTB_CONVERSION_RATE,
        COL_NTB_CONVERSIONS,
        COL_NTB_REVENUE,
        COL_NTB_REVENUE_RATE,
        COL_NTB_ROAS,
        COL_NTB_UNITS_SOLD,
        COL_NTB_UNITS_SOLD_RATE,
        COL_ROAS,
        COL_SPEND,
        COL_UNITS_SOLD,
        COL_ACCOUNT_ID,
        COL_AD_ID,
        COL_AD_SET_ID,
        COL_CAMPAIGN_ID
      ],
      rows: fetchRows(testRows),
      mapDataRowsToTableRows
    };
    return config;
  }, [viewLevel]);

  return (
    <Segment
      style={{
        height: "100%",
        overflowY: "hidden"
      }}
    >
      <div>
        <p
          style={{
            display: "block",
            margin: "0 0 0.3em 0.5em",
            fontWeight: "bold",
            fontSize: "small"
          }}
        >
          Level
        </p>
        <Dropdown
          style={{ marginBottom: "1rem" }}
          selection
          options={[
            { key: "campaign", text: "Campaign", value: "campaign" },
            { key: "adSet", text: "Ad Set", value: "adSet" },
            { key: "ad", text: "Ad", value: "ad" }
          ]}
          onChange={(e, { value }) =>
            setViewLevel(value as "campaign" | "adSet" | "ad")
          }
          value={viewLevel}
        />
      </div>
      <MetricsTable<FacebookTableData>
        tableConfig={tableConfig}
        initialFilters={[]}
        initialSort={{
          column: COL_NAME,
          direction: "asc"
        }}
        onUpdateSort={handleUpdateSort}
      />
    </Segment>
  );
}
