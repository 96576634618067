import React from "react";

import AmpdDataTable from "../../components/AmpdDataTable";
import { Table } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { getAmazonMarketplaceInfo } from "Common/utils/amazon";
import { prettyStringForEnum } from "Common/utils/proto";
import { GoogleAdsResourceStatus } from "Common/proto/ampdPb/googleAdsConfiguration_pb";
import { buildCampaignsPath } from "../../ExtensionV2";

const StatusOption = GoogleAdsResourceStatus.Option;

export const CampaignsTableHeading = ({ currentProduct }) => {
  const marketplace = currentProduct?.seller?.amazon?.marketplace;
  const marketplaceDomain = marketplace
    ? getAmazonMarketplaceInfo(marketplace)?.domain
    : null;
  const productUrl = currentProduct?.productPageUrl;

  return (
    <h5 style={{ textAlign: "initial" }}>
      Ampd campaigns advertising this {marketplaceDomain} product (ASIN:{" "}
      {productUrl ? (
        <a href={productUrl} target="_blank" rel="noopener noreferrer">
          {currentProduct?.productId}
        </a>
      ) : (
        currentProduct?.productId
      )}
      ):
    </h5>
  );
};

export const LinkedCampaignsSection = ({ currentProduct, error }) => (
  <AmpdDataTable
    columnDataNames={["campaignName", "statusName", "startDate"]}
    columnDisplayNamesMap={{
      campaignName: "Campaign Name",
      statusName: "Status",
      startDate: "Start Date"
    }}
    columnDisplayWidthsMap={{
      statusName: "8em",
      startDate: "8em"
    }}
    mapDataRowToComponent={(dataRow, columnNames, dataRowIndex) => (
      <Table.Row key={dataRowIndex}>
        {columnNames.map((column, index) => (
          <td key={index}>
            {column === "campaignName" ? (
              <Link to={`../${buildCampaignsPath(dataRow.campaignId)}`}>
                {dataRow[column]}
              </Link>
            ) : (
              `${dataRow[column] ?? "-"}`
            )}
          </td>
        ))}
      </Table.Row>
    )}
    dataRows={(currentProduct?.campaigns || []).map(c => {
      c.statusName = prettyStringForEnum(StatusOption, c.servingStatus);
      return c;
    })}
    defaultSortColum="campaignName"
    errorMessage={error}
  />
);
