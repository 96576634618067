import React, { useMemo } from "react";
import _ from "lodash";
import moment from "moment";

import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsNoDataToDisplay from "highcharts/modules/no-data-to-display";
import { buildCampaignStartVerticals } from "./charts/highchartsUtil";

import { useSearchParams } from "react-router-dom";
import { getAmazonMarketplaceInfo } from "Common/utils/amazon";

import styled from "styled-components/macro";
import { LoadingSpinner } from "Common/components/LoadingSpinner";
import { Box, Flex } from "@rebass/grid";
import { Message } from "semantic-ui-react";
import { Colors } from "app.css";

if (Highcharts) {
  HighchartsNoDataToDisplay(Highcharts);
}

const ChartBox = styled.div`
  width: 100%;
  padding-left: 2em;
  padding-right: 2em;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const searchTermRankMarkerOptions = [
  { color: "#66DDFF", symbol: "circle" },
  { color: "#00AADD", symbol: "circle" },
  { color: "#FF6688", symbol: "square" },
  { color: "#DD0066", symbol: "square" },
  { color: "#88FF66", symbol: "diamond" },
  { color: "#66DD00", symbol: "diamond" }
];

// smoothCenterAverage specifies whether moving averages should be centered
// rather than trailing averages.
const smoothCenterAverage = false;

// smoothNumDays is the number of days to consider in the moving average.
const smoothNumDays = 7;

// smoothMaxRankPoints determines whether "high rank" data points are treated
// by the smoothing algorithm as if they exist and have the value of the
// maximum rank for that day. If set to false, the smoothing algorithm will
// simply ignore the points.
const smoothMaxRankPoints = true;

// smoothMinValidPoints is the minimum number of non-missing data points we
// must have observed (in the past smoothNumDays days) in order to generate a
// moving average value for a day.
const smoothMinValidPoints = 2;

// relativeScaling determines whether the scale factor will be relative to the
// average rank for each data set, rather than absolute.
const relativeScaling = false;

const getSearchTermRankingSeriesInfo = searchTermRankings => {
  return searchTermRankings.flatMap((searchTermData, index) => {
    if (!searchTermData.searchTerm) {
      return [];
    }

    const organicMarkerOptions = searchTermRankMarkerOptions[index * 2];
    const sponsoredMarkerOptions = {
      unselected: true,
      ...searchTermRankMarkerOptions[index * 2 + 1]
    };
    const smoothedMarkerOptions = {
      unselected: true,
      dashStyle: "ShortDot",
      unlabeled: true,
      ...organicMarkerOptions
    };

    // Keep auxiliaryData to determine if a zero rank is zero because
    // we have no data or because it is probably beyond the number of pages
    // that we scanned.  For organic ranks, we will show a point in the
    // "High rank" section of the graph.  For sponsored ranks, we will
    // just assume that the search term is not sponsored in either case.
    const auxiliaryData = searchTermData.maxPositionsList;

    const hasSponsored = _.some(
      searchTermData.allVariantsSponsoredPositionsList,
      Boolean
    );
    // Always include a series for organic positions, but only return a
    // series for sponsored if there are some non-zero values.
    if (!hasSponsored) {
      return [
        {
          name: searchTermData.searchTerm,
          data: searchTermData.allVariantsOrganicPositionsList,
          auxiliaryData,
          scale: 0,
          ...organicMarkerOptions
        },
        {
          name: `${searchTermData.searchTerm} (smoothed)`,
          data: getMovingAverage(
            searchTermData.allVariantsOrganicPositionsList,
            auxiliaryData,
            smoothNumDays,
            smoothCenterAverage
          ),
          auxiliaryData,
          scale: 0,
          ...smoothedMarkerOptions
        }
      ];
    }

    return [
      {
        name: `${searchTermData.searchTerm} (organic)`,
        data: searchTermData.allVariantsOrganicPositionsList,
        auxiliaryData,
        scale: 0,
        ...organicMarkerOptions
      },
      {
        name: `${searchTermData.searchTerm} (organic, smoothed)`,
        data: getMovingAverage(
          searchTermData.allVariantsOrganicPositionsList,
          auxiliaryData,
          smoothNumDays,
          smoothCenterAverage
        ),
        auxiliaryData,
        scale: 0,
        ...smoothedMarkerOptions
      },
      {
        name: `${searchTermData.searchTerm} (sponsored)`,
        data: searchTermData.allVariantsSponsoredPositionsList,
        scale: 0,
        ...sponsoredMarkerOptions
      }
    ];
  });
};

const getCategoryRankingSeriesInfo = categoryRanks => {
  return categoryRanks.flatMap((category, index) => {
    const markerOptions = searchTermRankMarkerOptions[(index * 2) % 5];
    const smoothedMarkerOptions = {
      dashStyle: "ShortDot",
      unlabeled: true,
      unselected: true,
      ...markerOptions
    };

    const ranksScale = relativeScaling ? getScale(category.ranksList) : 0;

    return [
      {
        name: category.category,
        data: category.ranksList,
        scale: ranksScale,
        ...markerOptions
      },
      {
        name: `${category.category} (smoothed)`,
        data: getMovingAverage(
          category.ranksList,
          [],
          smoothNumDays,
          smoothCenterAverage
        ),
        scale: ranksScale,
        ...smoothedMarkerOptions
      }
    ];
  });
};

const AmazonRankingCharts = ({
  siteAlias,
  product,
  dateRangeEndDate,
  dateRangeStartDate,
  ranks,
  ranksLoading,
  ranksError
}) => {
  const [searchParams /*, setSearchParams */] = useSearchParams();

  // rankings across all campaigns for a product
  const [searchTermRanks, categoryRanks] = useMemo(() => {
    if (ranksLoading || ranksError || _.isEmpty(ranks)) {
      return [{}, {}];
    }

    let rankingsBySearchTerm = {};
    for (const termRanks of ranks.timeseries?.ranks?.searchTermRanksList) {
      rankingsBySearchTerm[termRanks.searchTerm] = termRanks;
    }

    let rankingsByCategory = {};
    for (const categoryRanks of ranks.timeseries?.ranks?.categoryRanksList) {
      rankingsByCategory[categoryRanks.category] = categoryRanks;
    }

    return [rankingsBySearchTerm, rankingsByCategory];
  }, [ranks, ranksLoading, ranksError]);

  const campaignCreationDates = useMemo(() => {
    return (product?.campaigns || [])
      .map(campaign => moment(campaign.campaignStartDate, "YYYY-MM-DD"))
      .filter(campaignStart =>
        campaignStart.isBetween(dateRangeStartDate, dateRangeEndDate)
      )
      .sort((a, b) => a - b);
  }, [product?.campaigns, dateRangeStartDate, dateRangeEndDate]);

  const searchTermRankChartConfig = useMemo(() => {
    if (_.isEmpty(searchTermRanks) || _.isEmpty(product)) {
      return null;
    }

    return makeChartConfig({
      title: "Organic Search Term Performance on Amazon",
      chartHeight: 450,
      siteAlias,
      product,
      startDate: moment(dateRangeStartDate, "YYYY-MM-DD"),
      endDate: moment(dateRangeEndDate, "YYYY-MM-DD"),
      searchParams,
      creationDate: campaignCreationDates[0],
      logarithmic: searchParams.has("ranking_log_scale"),
      isRanking: true,
      softMin: 1,
      softMax: 2,
      zeroValue: Infinity,
      seriesInfo: getSearchTermRankingSeriesInfo(Object.values(searchTermRanks))
    });
  }, [
    searchTermRanks,
    product,
    dateRangeStartDate,
    dateRangeEndDate,
    campaignCreationDates,
    searchParams,
    siteAlias
  ]);

  const categoryRankChartConfig = useMemo(() => {
    if (_.isEmpty(categoryRanks) || _.isEmpty(product)) {
      return null;
    }

    return makeChartConfig({
      title: "Best Sellers Rank on Amazon Within a Category",
      chartHeight: 450,
      siteAlias,
      product,
      startDate: moment(dateRangeStartDate, "YYYY-MM-DD"),
      endDate: moment(dateRangeEndDate, "YYYY-MM-DD"),
      searchParams,
      creationDate: campaignCreationDates[0],
      logarithmic: searchParams.has("ranking_log_scale"),
      isRanking: true,
      softMin: 1,
      softMax: 2,
      zeroValue: Infinity,
      seriesInfo: getCategoryRankingSeriesInfo(Object.values(categoryRanks)),
      yAxisTitleOffset: 110
    });
  }, [
    categoryRanks,
    product,
    dateRangeEndDate,
    dateRangeStartDate,
    campaignCreationDates,
    searchParams,
    siteAlias
  ]);

  return (
    <div>
      <Box>
        {ranksLoading ? (
          <LoadingSpinner>Loading Daily Amazon Rankings</LoadingSpinner>
        ) : ranksError ? (
          <Message error>There was an error loading rankings.</Message>
        ) : (
          <Flex flexDirection="column" justifyContent="space-between" py={10}>
            {!!searchTermRankChartConfig && (
              <ChartBox>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={searchTermRankChartConfig}
                />
              </ChartBox>
            )}
            {!!categoryRankChartConfig && (
              <ChartBox>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={categoryRankChartConfig}
                />
              </ChartBox>
            )}
          </Flex>
        )}
      </Box>
    </div>
  );
};

// Returns the Highcharts configuration for a single graph chart with multiple
// graphed series.
export function makeChartConfig({
  title,
  chartHeight,
  siteAlias,
  product,
  startDate,
  endDate,
  searchParams,
  creationDate,
  seriesInfo,
  isRanking,
  softMin,
  softMax,
  zeroValue,
  logarithmic,
  isClicksChart,
  showConversions,
  yAxisTitleOffset
}) {
  const marketplaceInfo = getAmazonMarketplaceInfo(product?.marketplace);

  // Collect the series data for the main graphed line and, if there is auxiliary data,
  // for the graphed points in the bottom "High rank" section.
  let hasAuxiliaryData = false;
  const series = seriesInfo.flatMap(
    ({
      name,
      data,
      auxiliaryData,
      unselected,
      unlabeled,
      color,
      symbol,
      dashStyle,
      scale,
      yAxis
    }) => {
      const seriesData = filterDataToDateRange(
        data,
        startDate,
        startDate,
        endDate,
        creationDate,
        zeroValue,
        auxiliaryData,
        undefined,
        scale
      );

      if (isClicksChart) {
        // Remove last day of metric data, which is incomplete.
        seriesData[seriesData.length - 1] = Infinity;
      }

      const series = [
        {
          name: name,
          yAxis: yAxis > 0 ? yAxis : 0,
          color,
          marker: {
            enabled: !unlabeled,
            symbol
          },
          dashStyle: dashStyle,
          clip: true,
          data: seriesData,
          dataLabels: unlabeled
            ? {
                enabled: false
              }
            : {
                format: isRanking ? "#{point.val}" : "{point.val}"
              },
          tooltip: {
            pointFormat:
              "{series.name}: <b>" + (isRanking ? "#" : "") + "{point.val}</b>"
          },
          enableMouseTracking: !unlabeled,
          selected: !unselected,
          visible: !unselected,
          showCheckbox: true,
          events: {
            checkboxClick: function() {
              /* Can't be arrow function and access 'this'. */
              if (this && this.chart.series) {
                this.chart.series.forEach(s => {
                  if (s.name === name) {
                    s[s.visible ? "hide" : "show"]();
                  }
                });
              }
            },
            legendItemClick: function() {
              /* Can't be arrow function and access 'this'. */
              if (this && this.chart.series) {
                const _this = this;
                const selected = this.selected;
                this.chart.series.forEach(s => {
                  if (s.name === name) {
                    if (s === _this) {
                      s.select(!selected);
                    } else {
                      s[s.visible ? "hide" : "show"]();
                    }
                  }
                });
              }
            }
          }
        }
      ];

      if (auxiliaryData && !unlabeled) {
        hasAuxiliaryData = true;

        const auxiliarySeriesData = filterDataToDateRange(
          data,
          startDate,
          startDate,
          endDate,
          creationDate,
          zeroValue,
          auxiliaryData,
          999
        );

        series.push({
          name: name,
          yAxis: 1,
          color,
          lineWidth: 0,
          tooltip: {
            pointFormat: "{series.name}: <b>Rank too high</b>"
          },
          states: {
            hover: {
              lineWidth: 0,
              lineWidthPlus: 0
            }
          },
          marker: {
            symbol
          },
          dataLabels: { enabled: false },
          data: auxiliarySeriesData,
          showInLegend: false
        });
      }

      return series;
    }
  );

  // The main plot area.
  const yAxis = [
    {
      height: hasAuxiliaryData ? "80%" : "100%",
      type: logarithmic ? "logarithmic" : "linear",
      title: {
        text: isClicksChart
          ? showConversions
            ? "Clicks and Conversions"
            : "Clicks"
          : yAxisTitleOffset
          ? " "
          : null,
        offset: yAxisTitleOffset
      },
      gridLineWidth: 1,
      reversed: isRanking,
      softMin: softMin,
      softMax: softMax,
      max: isFinite(zeroValue) && zeroValue > softMax ? softMax : undefined,
      allowDecimals: false,
      labels: {
        formatter:
          !isRanking || !relativeScaling
            ? undefined
            : function() {
                if (this.value === 1) {
                  return "#1";
                } else if (this.value === 2) {
                  return "Avg.\u00A0rank";
                }
                return "";
              },
        format: isRanking && !relativeScaling ? "#{value}" : undefined
      },
      tickInterval: !isRanking || !relativeScaling ? undefined : 1,
      tickPositioner: !isRanking
        ? undefined
        : function() {
            const ticks = this.tickPositions;
            if (ticks[0] === 0) {
              if (ticks[1] <= 1) {
                ticks.shift();
              } else {
                ticks[0] = 1;
              }
            } else if (ticks[0] > 1) {
              ticks.unshift(1);
            }

            return ticks;
          }
    }
  ];

  if (isClicksChart) {
    yAxis.push({
      ...yAxis[0],
      opposite: true,
      title: { text: "Impressions" }
    });
  }

  // If necessary, reserve the bottom 10% of the plot area for any "High rank"
  // section.
  if (hasAuxiliaryData) {
    yAxis.push({
      top: "90%",
      height: "10%",
      alignTicks: false,
      title: { text: "High\u00A0rank", rotation: 0 },
      labels: { enabled: false },
      plotBands: [
        {
          color: "#EEEEEE",
          from: -1000,
          to: 3000
        }
      ]
    });
    if (relativeScaling) {
      // If relative scaling is enabled, make "High rank" axis title appear
      // like another tick label.
      yAxis[1].title.margin = -25;
      yAxis[1].title.style = { fontSize: "11px" };
    }
  }

  return {
    chart: {
      type: "spline",
      plotBackgroundColor: Colors.lightGrey,
      plotBorderColor: Colors.black,
      borderWidth: 1,
      borderColor: Colors.black,
      animation: false,
      height: chartHeight,
      marginLeft: 90 // avoid big gap on left; align chart start
    },
    tooltip: {
      split: true,
      xDateFormat: "%b %e, %Y",
      useHTML: true // prevent blurry tooltips
    },
    credits: {
      enabled: false
    },
    title: {
      text: title
    },
    subtitle: {
      text: marketplaceInfo
        ? `${marketplaceInfo.domain} - ${product.productId} - ${product.title}`
        : ""
    },
    xAxis: {
      type: "datetime",
      labels: {
        overflow: "justify"
      },
      dateTimeLabelFormats: {
        day: "%b %e",
        week: "%b %e",
        hour: "",
        minute: "",
        second: ""
      },
      tickLength: 0,
      plotLines: buildCampaignStartVerticals(
        product.campaigns,
        siteAlias,
        searchParams,
        -250 // y-transform offset of the campaign start label for visibility
      )
    },
    yAxis,
    navigation: {
      menuItemStyle: {
        fontSize: "10px"
      }
    },
    legend: {
      layout: "horizontal" /* "proximate" */,
      align: "center",
      itemDistance: 3,
      itemCheckboxStyle: { width: "13px", height: "15px", position: "absolute" }
    },
    lang: {
      noData: product ? "No data available" : "Product data not available"
    },
    plotOptions: {
      series: {
        animation: false
      },
      spline: {
        dataLabels: {
          enabled: true,
          formatter() {
            return this.point.y ? this.point.y : "";
          }
        },
        lineWidth: 2,
        states: {
          hover: {
            lineWidth: 5
          },
          inactive: {
            opacity: 1 // Don't fade when hover other
          }
        },
        marker: {
          enabled: true,
          symbol: "circle"
        },
        pointInterval: 24 * 60 * 60 * 1000, // one day
        pointStart: startDate.toDate().valueOf()
      }
    },
    series
  };
}

// Returns series data based on the data list, but filtered to the
// specified date range.
function filterDataToDateRange(
  dataList,
  dataStartDate,
  dateRangeStartDate,
  dateRangeEndDate,
  creationDate,
  zeroValue,
  auxiliaryData,
  auxiliaryValue,
  scale
) {
  if (!dataList || !dataStartDate || !dateRangeStartDate) {
    return [];
  }

  const filteredData = dataList
    .map((value, index) => {
      const valueDate = moment(dataStartDate).add(index, "days");
      if (
        valueDate.isBefore(dateRangeStartDate) ||
        valueDate.isAfter(dateRangeEndDate)
      ) {
        return null;
      }

      if (creationDate && valueDate.isBefore(creationDate)) {
        return Infinity;
      }

      if (value === 0 && auxiliaryData) {
        // If there is no corresponding auxiliaryData value, don't include this
        // point at all.
        if (!auxiliaryData[index]) {
          return Infinity;
        }

        if (auxiliaryValue) {
          return auxiliaryValue;
        }

        return zeroValue;
      }

      // The data value is non-zero, but if we have an auxiliary value, we
      // are building the auxiliary series, so don't include this point.
      if (auxiliaryValue) {
        return Infinity;
      }

      if (zeroValue !== undefined && value === 0) {
        return zeroValue;
      }
      return value;
    })
    .filter(_.isNumber);

  return filteredData.map(value => {
    let y = value;
    if (scale > 0) {
      y = (y - 1) / (scale - 1) + 1;
    }
    return {
      y: y,
      val: value
    };
  });
}

// Computes a (modified) harmonic mean over "mod" days of data.
// Values are shifted up by 1 before the harmonic mean is computed in order to
// produce more intuitive behavior around low-valued ranks: this is equivalent
// to the assumption that the #2 search result gets 2/3 as much traffic as the
// #1 search result. (An ordinary harmonic mean would assume the #2 search
// result gets only 1/2 as much traffic as the #1 search result.)
// If "centered" is true then we will return data for a centered mean rather
// than a trailing mean.
export function getMovingAverage(dataList, maxRankData, mod, centered) {
  const weights = new Array(mod).fill(0.0);
  const sums = new Array(mod).fill(0.0);
  const found = new Array(mod).fill(0.0);
  let dayMax = 0.0;
  let ret = dataList.map((value, index) => {
    let pos = index % 7;
    if (maxRankData.length > 0) {
      dayMax = maxRankData[index];
    }
    if (value !== 0) {
      weights[pos] = 1;
      sums[pos] = 1 / (value + 1);
      found[pos] = 1;
    } else if (smoothMaxRankPoints && dayMax > 0) {
      weights[pos] = 1;
      sums[pos] = 1 / (dayMax + 1);
      found[pos] = 0;
    } else {
      weights[pos] = 0;
      sums[pos] = 0;
      found[pos] = 0;
    }
    const totalWeight = weights.reduce((a, b) => a + b);
    const totalSum = sums.reduce((a, b) => a + b);
    const totalFound = found.reduce((a, b) => a + b);
    if (totalWeight > 0 && totalFound >= smoothMinValidPoints) {
      return totalWeight / totalSum - 1;
    }
    return 0;
  });
  if (centered) {
    let reduceBy = Math.trunc(mod / 2);
    ret = ret.slice(reduceBy).concat(new Array(reduceBy).fill(0.0));
  }
  return ret;
}

// Computes the scale factor for a given data set when relativeScaling is true.
export function getScale(dataList) {
  const finiteList = dataList.filter(value => value > 0 && isFinite(value));
  if (finiteList.length > 0) {
    return finiteList.reduce((a, b) => a + b) / finiteList.length;
  } else {
    return 1;
  }
}

export default AmazonRankingCharts;
